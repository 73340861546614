import {
    iconRequest,
    iconClient,
    iconCalculator,
    iconSearch,
    iconPreAprobador,
    iconReports,
    iconReportsDefault,
    iconNeighborhood,
    iconConcessionaireSecondary,
    iconAffiliate,
    iconParameters,
    iconDocs,
    DocsDesembolsados,
} from '../defaultValues';

const data = {
    role_32fe968d846d4544ab7da197760f16f2: [
        {
            id: 'requests',
            icon: iconRequest,
            label: 'Solicitudes',
            to: '/app/credits',
        },
        {
            id: 'searcRequest',
            icon: iconSearch,
            label: 'Buscar Solicitud',
            to: '/app/reports/searchRequest',
        },

        {
            id: 'clients',
            icon: iconClient,
            label: 'Clientes',
            to: '/app/clients',
        },
        {
            id: 'documentos',
            icon: iconDocs,
            label: 'Documentos',
            to: '/app/calculator',
            subs: [
                {
                    id: 'documents',
                    icon: DocsDesembolsados,
                    label: 'Desembolsados',
                    to: '/app/credits/DisbursementDocuments',
                },
            ],
        },
        {
            id: 'parametros',
            icon: iconParameters,
            label: 'Parámetros',
            to: '/app/calculator',
            subs: [
                {
                    icon: iconConcessionaireSecondary,
                    label: 'Concesionarios',
                    to: '/app/concesionarios',
                },
                {
                    icon: iconAffiliate,
                    label: 'Aliados',
                    to: '/app/aliados',
                },
                {
                    icon: iconNeighborhood,
                    label: 'Barrios',
                    to: '/app/barrios',
                },
            ],
        },

        {
            id: 'pre-aprobador',
            icon: iconPreAprobador,
            label: 'Pre-aprobador',
            to: '/app/pre-aprobador',
        },
        {
            id: 'simulador-credito',
            icon: iconCalculator,
            label: 'Simulador de Crédito',
            to: '/app/calculator',
        },
        {
            id: 'blankpage',
            icon: iconReports,
            label: 'Reportes',
            to: '/app/blank-page',
            subs: [
                {
                    icon: iconReportsDefault,
                    label: 'Desempeño Usuario',
                    to: '/app/reports/TeamPerformance',
                },
            ],
        },
    ],
};
export default data;
