import roleMesaDeAprobacion from './roleMesaDeAprobacion/roleMesaDeAprobacion';
import roleZoneManager from './roleZoneManager/roleZoneManager';
import roleAdministrator from './roleAdministrator';

export default Object.assign(
    {},
    roleMesaDeAprobacion,
    roleZoneManager,
    roleAdministrator,
);
