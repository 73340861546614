import {
    MODAL_REASSIGN_USER_SET_DATA,
    MODAL_RESUME_REQUEST_SET_DATA,
    FILTER_DINAMIC_SET_DATA,
    GROUP_USER_REASING,
    IS_ACTIVE_CONCESSIONAIRE,
    LOADING_PEACE_SAFE,
    LOADING_GENERATE_DODUMENT,
    SET_BREADCRUMBS_ROUTES,
    FILTER_DINAMIC_COMPARATIVE_MONTHS,
    FILTER_DINAMIC_TEAM_PERFORMANCE,
    GROUP_USER_GENERAL,
    VIEW_BUTTON_EDIT_CONTACT_CLIENT,
    NO_SHOW_COMPONENT_IN_MENU,
    NO_SHOW_MODAL,
    CHANGE_INFO_CONTAINER,
    STATE_VERIFIK,
    STATE_VERIFIK_START,
} from '../actions';

const INIT_STATE = {
    modalReassignUser: null,
    modalResumeRequest: null,
    filterDinamicTable: {},
    groupUserReasign: null,
    isActiveConcessionaire: null,
    loadingPeaceAndSafe: null,
    loadingGenerateDocument: null,
    breadcrumbRoutes: {},
    filterDinamicComparative12Months: {},
    groupUserGeneral: null,
    filterDinamicTeamPerformance: {},
    viewButtonEditContactClient: null,
    noShowViewOptionsDocumentInMenu: [],
    noShowModal: false,
    changeInfoContainer: false,
    stateVerifik: {},
    stateVerifikStart: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MODAL_REASSIGN_USER_SET_DATA:
            return Object.assign({}, state, action.payload);
        case MODAL_RESUME_REQUEST_SET_DATA:
            return Object.assign({}, state, {
                modalResumeRequest: action.payload,
            });
        case FILTER_DINAMIC_SET_DATA:
            return Object.assign({}, state, action.payload);
        case GROUP_USER_REASING:
            return Object.assign({}, state, action.payload);
        case IS_ACTIVE_CONCESSIONAIRE:
            return Object.assign({}, state, action.payload);
        case LOADING_PEACE_SAFE:
            return Object.assign({}, state, action.payload);
        case LOADING_GENERATE_DODUMENT:
            return Object.assign({}, state, action.payload);
        case SET_BREADCRUMBS_ROUTES:
        case FILTER_DINAMIC_COMPARATIVE_MONTHS:
            return Object.assign({}, state, action.payload);
        case GROUP_USER_GENERAL:
            return Object.assign({}, state, action.payload);
        case FILTER_DINAMIC_TEAM_PERFORMANCE:
            return Object.assign({}, state, action.payload);
        case VIEW_BUTTON_EDIT_CONTACT_CLIENT:
            return Object.assign({}, state, action.payload);
        case NO_SHOW_COMPONENT_IN_MENU:
            return Object.assign({}, state, action.payload);
        case NO_SHOW_MODAL:
            return Object.assign({}, state, action.payload);
        case CHANGE_INFO_CONTAINER:
            return Object.assign({}, state, action.payload);
        case STATE_VERIFIK:
            return Object.assign({}, state, action.payload);
        case STATE_VERIFIK_START:
            return Object.assign({}, state, action.payload);
        default:
            return { ...state };
    }
};
