import React from 'react';
import { NavLink } from 'react-router-dom';

export const RedirectTableCell = ({ route, label }) => {
    return (
        <NavLink to={route} className="finamiga-color">
            {label}
        </NavLink>
    );
};
