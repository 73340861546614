const options = {
    estado_usuario: [
        {
            value: 1,
            label: 'ACTIVO',
        },
        {
            value: 0,
            label: 'INACTIVO',
        },
    ],
    tipo_usuario: [
        {
            value: '0',
            label: 'PREPAGO CONTENIDO DIGITAL',
        },
        {
            value: '1',
            label: 'POSTPAGO CONTENIDO DIGITAL',
        },
        {
            value: '2',
            label: 'PREPAGO CORRESPONSAL BANCARIO',
        },
        {
            value: '3',
            label: 'POSTPAGO CORRESPONSAL BANCARIO',
        },
    ],
};

export default options;
