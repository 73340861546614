import {
    monthsProduct,
    validationDefault,
    validationDefaultNew,
    defaultYearReport,
} from '../../components/utilities/rulesUtilities';

import genericOptions from '../defaultConfigRole/genericOptions';
import genericColumnsCalculator from '../defaultConfigRole/genericColumnsCalculator';
import { randomFloat } from '../../components/utilities/functions/General';
import { requestsListStatus } from './RequestsListStatus';
import { objGenericUrl } from '../defaultConfigRole/genericUrls';

const Rules = {
    role_32fe968d846d4544ab7da197760f16f2: {
        home: '/credits',
        requestsListStatus,
        views: {
            create: {
                titleRequestCredit: 'Solicitudes de créditos',
                permisions: ['processRequestCredit', 'listRequestCredit'],
                components: [
                    { name: 'Documentos', component: ['DocumentsCredit'] },
                    {
                        name: 'Solicitante',
                        component: ['InformationClient', 'FilterAutomatics'],
                    },
                    {
                        name: 'Contacto',
                        component: ['ContactsClient'],
                    },
                    {
                        name: 'Inf. solicitud',
                        component: ['InformationCredit', 'CalculatorCredit'],
                    },
                    {
                        name: 'Cónyuge',
                        component: ['ConyugeClient'],
                    },
                    {
                        name: 'Inf. negocio',
                        component: ['BusinessInformationCredit'],
                    },
                    { name: 'Referencias', component: ['ReferencesCredit'] },
                    { name: 'Codeudores', component: ['CodebtorCredit'] },
                    {
                        name: 'Análisis microempresa',
                        component: ['AnalisisCredit'],
                    },
                    { name: 'Ppi', component: ['PpiCredit'] },
                ],
                actionStatus: [
                    'rechazar',
                    'desistir',
                    'devolver',
                    'retornar',
                    'modalHallazgos',
                ],
                causalType: 7,
                groupUser: 4,
            },
            consult: {
                permisions: [
                    'consultClientList',
                    'ConsultClientEdit',
                    'InformationCredit:edit',
                ],
                components: [
                    {
                        name: 'Solicitante',
                        component: ['InformationClient'],
                    },
                    { name: 'Contacto', component: ['ContactsClient'] },
                    { name: 'Documentos', component: ['DocumentsClient'] },
                    {
                        name: 'Solicitudes cliente',
                        component: ['RequestsClient'],
                    },
                    { name: 'Crédito', component: ['GygClient'] },
                ],
                noShowFieldsConsult: false,
            },
            profile: {
                fields: [
                    {
                        name: 'identification_number',
                        label: 'Número de identificación',
                        disabled: false,
                        type: 'integer',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'first_name',
                        label: 'Nombres',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'nombres'
                        ),
                    },
                    {
                        name: 'last_name',
                        label: 'Apellidos',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'apellidos'
                        ),
                    },
                    {
                        name: 'phone_number',
                        label: 'Número telefónico',
                        disabled: false,
                        type: 'celular',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'número telefónico'
                        ),
                    },
                ],
            },
        },
        components: {
            informationClient: {
                permisions: [
                    'informationClient:list',
                    'informationClient:edit',
                ],
                automaticFilters: true,
                fields: [
                    {
                        name: 'tipo_identificacion',
                        label: 'Tipo identificación',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_de_documento,
                        validation: validationDefault(
                            ['required'],
                            'tipo de identificación'
                        ),
                    },
                    {
                        label: 'Número de identificación',
                        name: 'numero_identificacion',
                        disabled: true,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'primer_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'segundo_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'segundo nombre'
                        ),
                    },
                    {
                        name: 'primer_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'primer apellido'
                        ),
                    },
                    {
                        name: 'segundo_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'segundo apellido'
                        ),
                    },
                    {
                        name: 'genero',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.genero,
                        validation: validationDefault(['required'], 'genero'),
                    },
                    {
                        name: 'lugar_expedicion_documento',
                        label: 'Lugar expedición documento',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de expedición documento'
                        ),
                    },
                    {
                        name: 'fecha_expedicion_documento',
                        label: 'Fecha expedición documento',
                        disabled: false,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'fecha de expedición del documento'
                        ),
                    },
                    {
                        name: 'lugar_nacimiento',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de nacimiento'
                        ),
                    },
                    {
                        name: 'fecha_nacimiento',
                        disabled: false,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Fecha de nacimiento'
                        ),
                    },
                    {
                        name: 'grupo_social',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.grupo_etnico,
                    },
                    {
                        name: 'peps',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.direccion_principal,
                        validation: validationDefault(['required'], 'Peps'),
                    },
                ],
            },
            socioeconomicClient: {
                permisions: [
                    'socioeconomicClient:list',
                    'socioeconomicClient:edit',
                ],
                fields: [
                    {
                        name: 'estado_civil',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.estado_civil,
                        validation: validationDefault(
                            ['required'],
                            'estado civil'
                        ),
                    },
                    {
                        name: 'tipo_vivienda',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_vivienda,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'tipo vivienda'
                        ),
                    },
                    {
                        name: 'estrato',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 6 },
                                { type: 'min', value: 1 },
                            ],
                            'estrato'
                        ),
                    },

                    {
                        label: 'Antigüedad residencia (meses)',
                        name: 'antiguedad_residencia',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 900 },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad residencia'
                        ),
                    },
                    {
                        name: 'tamano_hogar',
                        label: 'Integrantes del hogar',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 50 },
                                { type: 'min', value: 1 },
                            ],
                            'Integrantes del hogar'
                        ),
                    },
                    {
                        name: 'personas_a_cargo',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 10 },
                                { type: 'min', value: 0 },
                            ],
                            'personas a cargo'
                        ),
                    },
                    {
                        name: 'numero_hijos',
                        label: 'Número hijos',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 20 },
                                { type: 'min', value: 0 },
                            ],
                            'número de hijos'
                        ),
                    },
                    {
                        name: 'escolaridad',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.escolaridad,
                        validation: validationDefault(
                            ['required'],
                            'escolaridad'
                        ),
                    },
                    {
                        name: 'naturaleza',
                        disabled: true,
                        type: 'hidden',
                        col: 3,
                        isMulti: false,
                    },
                    {
                        name: 'ciiu',
                        label: 'Actividad económica',
                        disabled: false,
                        type: 'autocomplete',
                        col: 6,
                        options: [],
                        url: '/globals/ciiu/',
                        validation: validationDefault(
                            ['required'],
                            'Actividad económica'
                        ),
                    },
                    {
                        name: 'descripcion_actividad_economica',
                        label: 'Descripción actividad económica',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 230 },
                            ],
                            'descripción actividad económica'
                        ),
                    },
                ],
            },
            contactsClient: {
                permisions: [
                    'contactsClient:list',
                    'contactsClient:edit',
                    'contactsClient:editButton',
                    'contactsClient:deleteButton',
                ],
                tittle: 'solicitante',
                fields: [
                    {
                        name: 'ciudad_contacto',
                        label: 'Ciudad',
                        disabled: false,
                        type: 'autocomplete-dependency',
                        col: 6,
                        showTable: true,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'barrio_contacto',
                        url: '/globals/ciudades/?departamento={0}',
                        reduxParams: ['globals.departamentos_regional'],
                        searchSecondParam: true,
                        validation: validationDefault(
                            ['required'],
                            'ciudad de contacto'
                        ),
                    },
                    {
                        name: 'barrio_contacto',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        showTable: true,
                        dependency: 'ciudad_contacto',
                        options: [],
                        url: '/globals/barrios/',
                        validation: validationDefault(
                            ['required'],
                            'barrio de contacto'
                        ),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección',
                        disabled: false,
                        type: 'address',
                        col: 6,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 255 },
                                { type: 'minLength', value: 2 },
                            ],
                            'dirección'
                        ),
                    },
                    {
                        name: 'referencia_ubicacion',
                        label: 'Referencia ubicación',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        showTable: true,
                    },
                    {
                        name: 'telefono',
                        label: 'Teléfono',
                        disabled: false,
                        type: 'phone',
                        col: 6,
                    },
                    {
                        name: 'celular',
                        label: 'Celular',
                        disabled: false,
                        type: 'celular',
                        col: 6,
                        showTable: true,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'celular'
                        ),
                    },
                    {
                        name: 'correo_electronico',
                        label: 'Correo electrónico',
                        disabled: false,
                        type: 'email',
                        col: 6,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'Correo electrónico'
                        ),
                    },
                    {
                        name: 'principal',
                        label: 'Dirección principal',
                        disabled: false,
                        type: 'select',
                        options: genericOptions.direccion_principal,
                        defaultValue: [],
                        col: 6,
                        showTable: true,
                    },
                ],
                defaultValues: {
                    direccion: 'carrera 1 calle 1 # 1',
                    barrio: 'Ciudad modelo',
                    latitud: '11111324',
                    longitud: '38947239',
                    telefono: '',
                    celular: '',
                    correo_electronico: 'test@finamiga.co',
                    referencia_ubicacion: 'cerca a... diagonal a...',
                    tipo_ubicacion: 1,
                    departamento: 5,
                    ciudad_contacto: 1205,
                },
            },
            informationCredit: {
                permisions: [
                    'informationCredit:list',
                    'informationCredit:edit',
                ],
                fields: [
                    {
                        name: 'subproducto',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: [],
                        parentDependency: true,
                        url: '/globals/subproducto/',
                        validation: validationDefault(
                            ['required'],
                            'subproducto'
                        ),
                    },

                    {
                        name: 'oficina',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'ejecutivo',
                        url: '/globals/oficinas/',
                        validation: validationDefault(['required'], 'oficina'),
                    },
                    {
                        name: 'ejecutivo',
                        disabled: true,
                        type: 'select',
                        col: 6,
                        dependency: 'oficinas',
                        url: '/globals/users/?groups=3&is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(
                            ['required'],
                            'ejecutivo'
                        ),
                    },
                    {
                        name: 'origen',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.origen,
                        validation: validationDefault(['required'], 'origen'),
                    },
                    {
                        name: 'medio',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.medio,
                        validation: validationDefault(['required'], 'medio'),
                    },
                    {
                        name: 'tipo_credito',
                        label: 'Tipo crédito',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_credito,
                        validation: validationDefault(
                            ['required'],
                            'tipo crédito'
                        ),
                    },
                    {
                        name: 'seguro_voluntario',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.seguro_voluntario,
                        validation: validationDefault(
                            ['required'],
                            'seguro voluntario'
                        ),
                    },
                    {
                        label: 'Seguro SBS',
                        name: 'tipo_seguro',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_seguro_sbs,
                        validation: validationDefault(
                            ['required'],
                            'seguro sbs'
                        ),
                    },
                    // INFORMACION DEL FONDO NACIONAL DE GARANTIAS
                    {
                        name: 'tipo_garantia',
                        label: 'Garantía',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.garantia,
                    },
                ],
                fieldsFng: [
                    {
                        name: 'fng_producto',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        url: '/globals/fng_productos/',
                        parentDependency: true,
                        childrenDependency: 'fng_porcentaje',
                        validation: validationDefault(
                            ['required'],
                            'fng producto'
                        ),
                    },
                    {
                        name: 'fng_porcentaje',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        dependency: 'fng_producto',
                        url: '/globals/fng_porcentajes/', //?fng_producto=4',
                        validation: validationDefault(
                            ['required'],
                            'fng porcentaje'
                        ),
                    },
                ],
                fieldsDynamic: [],
                defaultValues: {
                    oficina: 9,
                    producto: 17,
                    origen: 1,
                    medio: 1,
                    tipo_credito: 1,
                    monto_solicitado: 5000000,
                    plazo_solicitado: 2,
                    cuota_inicial_solicitada: 1500000,
                    asesor: 'asesor test',
                    telefono_asesor: '123456798',
                    concesionario: 'concesionario test',
                    marca: 'Bajaj',
                    linea: 'Pulsar',
                    valor: 8000000,
                    soat: 480000,
                    impuestos: 120000,
                    matricula: 200000,
                    nombre_concesionario: 'concesionario test!',
                    tipo_motocicleta: 'motocicleta',
                },
            },
            productCredit: {
                permisions: ['productCredit:list', 'productCredit:edit'],
                fields: [],
                filedsConditional: {
                    p2: [
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocicleta,
                            defaultValue: {
                                label: 'MOTOCICLETA',
                                value: 0,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 6,
                        },
                    ],
                    p3: [
                        // Prestamiga
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(3),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p8: [
                        // Prestamiga Preferencial
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(8),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p10: [
                        // Prestamiga Renovacion
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(10),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p18: [
                        // Prestamiga Reactivacion
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(18),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p6: [
                        // Cultiva
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                [''],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 4,
                        },
                        {
                            name: 'seguro_agricola',
                            label: 'Seguro agrícola',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'seguro agrícola'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(6),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p12: [
                        // Cultiva renovacion
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                [''],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                        },
                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 4,
                        },
                        {
                            name: 'seguro_agricola',
                            label: 'Seguro agrícola',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'seguro agrícola'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(12),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p14: [
                        // Cultiva preferencial
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                [''],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                        },
                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 4,
                        },
                        {
                            name: 'seguro_agricola',
                            label: 'Seguro agrícola',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'seguro agrícola'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(14),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p17: [
                        // Vehiculos
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },

                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'linea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo de vehículo',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_vehiculo,
                            validation: validationDefault(
                                ['required'],
                                'tipo de vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matricula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(17),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 6,
                        },
                    ],
                    p40: [
                        // Vehiculos usados
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'linea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo de vehículo',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: genericOptions.tipo_vehiculo,
                            validation: validationDefault(
                                ['required'],
                                'tipo de vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'matricula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(17),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'fasecolda',
                            disabled: false,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'fasecolda'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 4,
                        },
                    ],
                    p41: [
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },

                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocicleta,
                            defaultValue: {
                                label: 'MOTOCICLETA',
                                value: 0,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 6,
                        },
                    ],
                    p42: [
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocarro,
                            defaultValue: {
                                label: 'MOTOCARRO',
                                value: 1,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 6,
                        },
                    ],
                    p43: [
                        {
                            name: 'concesionario',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            childrenDependency: 'aliado_concesionario',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'aliado_concesionario',
                            label: 'Aliado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            url: '/globals/users/?groups=11&is_active=true',
                            dependency: 'concesionario',
                            notSearchGlobals: true,
                            validation: validationDefault(
                                ['required'],
                                'Aliado'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocarro,
                            defaultValue: {
                                label: 'MOTOCARRO',
                                value: 1,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: false,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },

                        {
                            name: 'numero_aliado',
                            disabled: true,
                            type: 'string',
                            col: 6,
                        },
                    ],
                },
            },
            documentsCredit: {
                permisions: ['documentsCredit:list', 'documentsCredit:edit'],
                fields: [
                    {
                        name: 'solicitud_tipo_documento',
                        label: 'Tipo documento',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        url: '/solicitudes/tipo_documentos/?roles=4',
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        name: 'documento',
                        disabled: false,
                        type: 'file',
                        col: 5,
                        validation: validationDefault(
                            ['required'],
                            'documento'
                        ),
                    },
                ],
            },
            documentsClient: {
                permisions: ['documentsClient:list', 'documentsClient:edit'],
                fields: [
                    {
                        name: 'persona_tipo_documento',
                        label: 'Tipo documento',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        url: '/personas/tipo_documentos/?roles=4',
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        name: 'documento',
                        disabled: false,
                        type: 'file',
                        col: 5,
                        validation: validationDefault(
                            ['required'],
                            'documento'
                        ),
                    },
                ],
            },
            documentsDisbursement: {
                permisions: ['documentsDisbursement:list'],
                fields: [
                    {
                        name: 'solicitud_tipo_documento',
                        label: 'Tipo documento',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        url: '/solicitudes/tipo_documentos/',
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        name: 'documento',
                        disabled: false,
                        type: 'file',
                        col: 5,
                        validation: validationDefault(
                            ['required'],
                            'documento'
                        ),
                    },
                ],
            },
            observationCredit: {
                permisions: [
                    'observationCredit:list',
                    'observationCredit:edit',
                ],
                fields: [
                    {
                        className: 'input-observacion',
                        name: 'observacion',
                        label: 'Observación',
                        disabled: false,
                        type: 'string',
                        rows: 1,
                        typeColumn: 2,
                        col: 12,
                        showTable: true,
                        staticCol: 12,
                        validation: validationDefault(
                            ['required'],
                            'observación'
                        ),
                    },
                ],
            },
            calculatorCredit: {
                permisions: [
                    'calculatorCredit:list',
                    'calculatorCredit:edit',
                    'calculatorCredit:simulator',
                ],
                summaryCredit: genericColumnsCalculator.summaryCredit,
                monthlyValues: genericColumnsCalculator.monthlyValues,
                defaultValues: {},
                fields: [
                    {
                        name: 'subproducto',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: [],
                        parentDependency: true,
                        url: '/globals/subproducto/',
                        validation: validationDefault(
                            ['required'],
                            'subproducto'
                        ),
                    },
                    {
                        name: 'seguro_voluntario',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.seguro_voluntario,
                        validation: validationDefault(
                            ['required'],
                            'seguro voluntario'
                        ),
                    },
                    {
                        label: 'Seguro SBS',
                        name: 'tipo_seguro',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_seguro_sbs,
                        validation: validationDefault(
                            ['required'],
                            'seguro sbs'
                        ),
                    },
                ],
            },
            ReferencesCredit: {
                fields: [
                    {
                        name: 'celular',
                        disabled: false,
                        type: 'celular',
                        col: 3,
                        validation: validationDefault(['required'], 'celular'),
                    },
                    {
                        name: 'primer_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'segundo_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'primer_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'primer apellido'
                        ),
                    },
                    {
                        name: 'segundo_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'segundo apellido'
                        ),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección',
                        disabled: false,
                        type: 'address',
                        col: 6,
                        validation: validationDefault(
                            ['required'],
                            'dirección'
                        ),
                        showTable: true,
                    },
                    {
                        name: 'ciudad',
                        label: 'Ciudad',
                        disabled: false,
                        type: 'autocomplete-dependency',
                        col: 3,
                        showTable: true,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'barrio_referencia',
                        url: '/globals/ciudades/',
                        validation: validationDefault(['required'], 'ciudad'),
                    },
                    {
                        name: 'barrio_referencia',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        dependency: 'ciudad',
                        options: [],
                        url: '/globals/barrios/',
                        validation: validationDefault(
                            ['required'],
                            'barrio referencia'
                        ),
                    },
                    {
                        name: 'estado_referencia',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.estado_referencia,
                        validation: validationDefault(
                            ['required'],
                            'estado referencia'
                        ),
                    },
                    {
                        name: 'parentesco',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_parentesco,
                        validation: validationDefault(
                            ['required'],
                            'parentesco'
                        ),
                    },
                    {
                        name: 'tipo_referencia',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_referencia,
                        validation: validationDefault(
                            ['required'],
                            'tipo referencia'
                        ),
                    },
                    {
                        name: 'observacion',
                        label: 'Observación',
                        disabled: false,
                        type: 'string',
                        col: 12,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 1000 },
                                { type: 'minLength', value: 3 },
                            ],
                            'observación'
                        ),
                    },
                ],
                fieldsKnowsHim: [
                    {
                        name: 'recomendaria',
                        label: 'Recomendaría',
                        disabled: false,
                        type: 'switch',
                        col: 2,
                        typeColumn: 2,
                    },
                    {
                        name: 'actividad_economica',
                        label: 'Actividad económia',
                        disabled: false,
                        type: 'string',
                        col: 5,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 200 },
                            ],
                            'actividad económica'
                        ),
                    },
                    {
                        name: 'antiguedad',
                        label: '¿Hace cuanto tiempo lo conoce?',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        options: genericOptions.conoce_cliente,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 900 },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad'
                        ),
                    },
                ],
                defaultValues: {
                    primer_nombre: 'pruebas1',
                    primer_apellido: 'sistemas',
                    segundo_apellido: 'sistemas 2',
                    celular: '3249872938',
                    direccion: 'carrera 1 calle 1 # 1',
                    barrio: 'Ciudad modelo',
                    estado_referencia: 1,
                    parentesco: 0,
                    tipo_referencia: 0,
                    conoce: true,
                    recomendaria: false,
                    actividad_economica: 'venta de pollos',
                    observacion: 'ninguna',
                    antiguedad: 2,
                },
            },
            analysisCredit: {
                permisions: ['analysisCredit:list', 'analysisCredit:edit'],
                fields: [
                    {
                        name: 'LIQUIDEZ DEL NEGOCIO',
                        desc: '',
                        fields: [
                            {
                                name: 'ventas',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'ventas'
                                ),
                            },
                            {
                                name: 'costo_venta',
                                disabled: false,
                                type: 'percentage',
                                col: 6,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxValueString', value: 100 },
                                    ],
                                    'costo de ventas'
                                ),
                            },
                            {
                                name: 'total_gastos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total gastos'
                                ),
                            },
                            {
                                name: 'pagos_deudas',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'pago deudas'
                                ),
                            },
                            {
                                name: 'utilidad_neta',
                                disabled: true,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'utilidad neta'
                                ),
                            },
                        ],
                    },
                    {
                        name: 'LIQUIDEZ FAMILIAR',
                        desc: '',
                        fields: [
                            {
                                name: 'otros_ingresos',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'otros ingresos'
                                ),
                            },
                            {
                                name: 'total_gastos_familiar',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'total gastos'
                                ),
                            },
                            {
                                name: 'liquidez_total',
                                disabled: true,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'liquidez total'
                                ),
                            },
                            {
                                name: 'capacidad_pago',
                                disabled: true,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'capacidad de pago'
                                ),
                            },
                        ],
                    },
                    {
                        name: 'BALANCE GENERAL DE LA MICROEMPRESA',
                        desc: '',
                        fields: [
                            {
                                name: 'activos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total activos'
                                ),
                            },
                            {
                                name: 'pasivos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total pasivos'
                                ),
                            },
                            {
                                name: 'patrimonio',
                                disabled: true,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total patrimonio'
                                ),
                            },
                        ],
                    },
                    {
                        name: 'INDICADORES FINANCIEROS',
                        desc: '',
                        fields: [
                            {
                                name: 'capacidad_pago_porcentaje',
                                label: 'Capacidad pago porcentaje (%)',
                                disabled: true,
                                type: 'integer',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'capacidad de pago'
                                ),
                            },
                            {
                                name: 'capital_trabajo',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'capital de trabajo'
                                ),
                            },
                            {
                                name: 'endeudamiento_actual',
                                label: 'Endeudamiento actual (%)',
                                disabled: true,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'endeudamiento actual'
                                ),
                            },
                            {
                                name: 'endeudamiento_futuro',
                                lable: 'Endeudamiento futuro (%)',
                                disabled: true,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'endeudamiento futuro'
                                ),
                            },
                            {
                                name: 'rotacion',
                                label: 'Rotación de inventario (Días)',
                                disabled: false,
                                type: 'integer',
                                col: 4,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 3 },
                                    ],
                                    'rotacion'
                                ),
                            },
                            {
                                name: 'liquidez_financiera',
                                label: 'Liquidez razón corriente',
                                disabled: false,
                                type: 'integer',
                                col: 4,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 3 },
                                    ],
                                    'liquidez financiera'
                                ),
                            },
                        ],
                    },
                    {
                        name: 'PROPUESTA',
                        desc: '',
                        fields: [
                            {
                                name: 'propuesta_economica',
                                label: 'Propuesta económica',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'propuesta económica ejecutivo'
                                ),
                            },
                            {
                                name: 'plazo_propuesta',
                                label: 'Plazo propuesto',
                                disabled: false,
                                type: 'select',
                                options: monthsProduct(17),
                                col: 6,
                                validation: validationDefaultNew(
                                    [{ type: 'required', value: true }],
                                    'plazo propuesto'
                                ),
                            },
                            {
                                name: 'cuota_inicial_propuesta',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'cuota inicial'
                                ),
                            },
                            {
                                name: 'cuota_mensual_propuesta',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'cuota_mensual'
                                ),
                            },
                        ],
                    },
                ],
                defaultValues: {
                    ventas: Math.floor(randomFloat(0, 1) * 1000000),
                    costo_venta: Math.floor(randomFloat(0, 1) * 10),
                    total_gastos: Math.floor(randomFloat(0, 1) * 10000),
                    pagos_deudas: 0,
                    utilidad_neta: 0,
                    otros_ingresos: 0,
                    total_gastos_familiar: Math.floor(
                        randomFloat(0, 1) * 10000
                    ),
                    liquidez_total: 0,
                    capacidad_pago: 0,
                    activos: Math.floor(randomFloat(0, 1) * 10000),
                    pasivos: Math.floor(randomFloat(0, 1) * 10000),
                    patrimonio: 0,
                    capacidad_pago_porcentaje: 0,
                    capital_trabajo: Math.floor(randomFloat(0, 1) * 10000),
                    endeudamiento_actual: 0,
                    endeudamiento_futuro: 0,
                    rotacion: Math.floor(randomFloat(0, 1) * 10000),
                    liquidez_financiera: 5,
                    propuesta_economica: Math.floor(
                        randomFloat(0, 1) * 1000000
                    ),
                    plazo_propuesta: Math.floor(randomFloat(0, 1) * 10),
                    cuota_inicial_propuesta: 0,
                    cuota_mensual_propuesta: Math.floor(
                        randomFloat(0, 1) * 10000
                    ),
                },
            },
            Ppi: {
                permission: ['Ppi:list', 'Ppi:edit'],
                fields: [
                    {
                        id: '1',
                        question: '¿Cuántos miembros tiene el hogar?',
                        options: [
                            {
                                response: 'A. 2 o menos',
                                points: 20,
                                idQuestions: 'ppi_1_1',
                            },
                            {
                                response: 'B. 3 a 4',
                                points: 10,
                                idQuestions: 'ppi_1_2',
                            },
                            {
                                response: 'C. 5 a 6',
                                points: 3,
                                idQuestions: 'ppi_1_3',
                            },
                            {
                                response: 'D. 7 o más',
                                points: 0,
                                idQuestions: 'ppi_1_4',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_1',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 1'
                        ),
                    },
                    {
                        id: '2',
                        question: '¿Todos los niños entre los 6 y 12?',
                        options: [
                            {
                                response:
                                    'A. Al menos un niño entre 6 y 12 años no asiste',
                                points: 0,
                                idQuestions: 'ppi_2_1',
                            },
                            {
                                response: 'B. No hay niños entre 6 y 12 años',
                                points: 7,
                                idQuestions: 'ppi_2_2',
                            },
                            {
                                response:
                                    'C. Todos los niños entre 6 y 12 años asisten',
                                points: 1,
                                idQuestions: 'ppi_2_3',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_2',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 2'
                        ),
                    },
                    {
                        id: '3',
                        question:
                            '¿Algún miembro de este hogar alcanzó un nivel educativo superior o universitario?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 6,
                                idQuestions: 'ppi_3_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_3_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_3',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 3'
                        ),
                    },
                    {
                        id: '4',
                        question:
                            '¿Algún miembro de este hogar tiene un trabajo con un contrato escrito?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 15,
                                idQuestions: 'ppi_4_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_4_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_4',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 4'
                        ),
                    },
                    {
                        id: '5',
                        question:
                            '¿Algún miembro de este hogar es un obrero o empleado de empresa particular/privada?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 9,
                                idQuestions: 'ppi_5_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_5_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_5',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 5'
                        ),
                    },
                    {
                        id: '6',
                        question:
                            '¿Algún miembro de este hogar es un patrón o empleador?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 15,
                                idQuestions: 'ppi_6_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_6_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_6',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 6'
                        ),
                    },
                    {
                        id: '7',
                        question:
                            '¿Este hogar tiene una máquina lavadora de ropa?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 6,
                                idQuestions: 'ppi_7_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_7_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_7',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 7'
                        ),
                    },
                    {
                        id: '8',
                        question: '¿Este hogar tiene un horno microondas?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 8,
                                idQuestions: 'ppi_8_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_8_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_8',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 8'
                        ),
                    },
                    {
                        id: '9',
                        question: '¿Este hogar tiene un carro particular?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 5,
                                idQuestions: 'ppi_9_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_9_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_9',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 9'
                        ),
                    },
                    {
                        id: '10',
                        question:
                            '¿Cuál es el material predominante de los pisos de la vivienda?',
                        options: [
                            {
                                response:
                                    'A. Tierra, arena, madera burda, tabla, tablón, otro vegetal',
                                points: 0,
                                idQuestions: 'ppi_10_1',
                            },
                            {
                                response: 'B. Cemento, gravilla',
                                points: 2,
                                idQuestions: 'ppi_10_2',
                            },
                            {
                                response:
                                    'C. Baldosín, ladrillo, vinisol, otros materiales sintéticos',
                                points: 9,
                                idQuestions: 'ppi_10_3',
                            },
                            {
                                response:
                                    'D. Mármol, madera pulida, alfombra o tapete de pared a pared',
                                points: 6,
                                idQuestions: 'ppi_10_4',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_10',
                        disabled: false,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 10'
                        ),
                    },
                ],
            },
            BusinessInformationCredit: {
                permisions: [
                    'businessInformationCredit:list',
                    'businessInformationCredit:edit',
                ],
                fields: [
                    {
                        name: 'nombre',
                        label: 'Nombre del negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'minLength', value: 5 },
                            ],
                            'nombre del negocio'
                        ),
                    },
                    {
                        name: 'antiguedad',
                        label: 'Antigüedad de negocio (meses)',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 900 },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad de negocio (meses)'
                        ),
                    },
                    {
                        name: 'antiguedad_local',
                        label: 'Antigüedad de negocio en local (meses)',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 900 },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad de negocio en local'
                        ),
                    },
                    {
                        name: 'empleados_permanentes',
                        label: 'Empleados permanentes',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 50 },
                                { type: 'min', value: 0 },
                            ],
                            'empleados permanentes del negocio'
                        ),
                    },
                    {
                        name: 'empleados_temporales',
                        label: 'Empleados temporales',
                        disabled: false,
                        type: 'integer',
                        col: 2,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 50 },
                                { type: 'min', value: 0 },
                            ],
                            'empleados temporales del negocio'
                        ),
                    },
                    {
                        name: 'negocio_ciiu',
                        label: 'Actividad del negocio',
                        disabled: false,
                        type: 'autocomplete',
                        col: 6,
                        options: [],
                        url: '/globals/ciiu/',
                        validation: validationDefault(
                            ['required'],
                            'Actividad del negocio'
                        ),
                    },
                    {
                        name: 'descripcion_actividad',
                        label: 'Descripción de la actividad del negocio',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 230 },
                            ],
                            'descripción de la actividad del negocio'
                        ),
                    },
                    {
                        name: 'telefono',
                        label: 'Teléfono del negocio',
                        disabled: false,
                        type: 'phone',
                        col: 3,
                    },
                    {
                        name: 'celular',
                        label: 'Celular',
                        disabled: false,
                        type: 'celular',
                        col: 3,
                        validation: validationDefault(['required'], 'celular'),
                    },
                    {
                        name: 'ciudad_negocio',
                        label: 'Ciudad del negocio',
                        disabled: false,
                        type: 'autocomplete-dependency',
                        col: 3,
                        showTable: true,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'barrio_negocio',
                        url: '/globals/ciudades/?departamento={0}',
                        reduxParams: ['globals.departamentos_regional'],
                        searchSecondParam: true,
                        validation: validationDefault(
                            ['required'],
                            'ciudad del negocio'
                        ),
                    },
                    {
                        name: 'barrio_negocio',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        dependency: 'ciudad_negocio',
                        options: [],
                        url: '/globals/barrios/',
                        validation: validationDefault(
                            ['required'],
                            'barrio del negocio'
                        ),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección del negocio',
                        disabled: false,
                        type: 'address',
                        col: 6,
                        validation: validationDefault(
                            ['required'],
                            'dirección del negocio'
                        ),
                    },
                    {
                        name: 'referencia_ubicacion',
                        label: 'Referencia para ubicación del negocio',
                        disabled: false,
                        type: 'string',
                        col: 6,
                    },
                    {
                        name: 'sector_economico',
                        label: 'Sector económico del negocio',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        options: genericOptions.sector_economico,
                        validation: validationDefault(
                            ['required'],
                            'sector económico del negocio'
                        ),
                    },
                    {
                        name: 'tipo_local',
                        label: 'Tipo de local',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        options: genericOptions.tipo_local,
                        validation: validationDefault(
                            ['required'],
                            'tipo de local del negocio'
                        ),
                    },

                    {
                        name: 'rut',
                        label: 'RUT',
                        disabled: false,
                        type: 'checkbox',
                        col: 1,
                        typeColumn: 3,
                    },
                    {
                        name: 'camara_comercio',
                        label: 'Cámara de comercio',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'registra_compras',
                        label: 'Registra compras',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'registra_ventas',
                        label: 'Registra ventas',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'eps',
                        label: 'EPS',
                        disabled: false,
                        type: 'checkbox',
                        col: 1,
                        typeColumn: 3,
                    },
                    {
                        name: 'sisben',
                        label: 'SISBEN',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'responsable_iva',
                        label: 'Responsable de IVA',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                ],
                defaultValues: {
                    tipo_negocio: 'venta de papa',
                    nombre: 'papaMania',
                    antiguedad: 8,
                    antiguedad_local: 5,
                    descripcion_actividad: 'venta de papa',
                    telefono: '7230000',
                    celular: '3164907501',
                    direccion: 'calle  8norte  13a  45',
                    barrio: 'pruebas',
                    referencia_ubicacion: 'en la esquina cruza',
                    sector_economico: 0,
                    tipo_local: 0,
                    empleados_permanentes: 1,
                    empleados_temporales: 1,
                },
            },
            conyugeClient: {
                permisions: ['conyugeClient:list', 'conyugeClient:edit'],
                fields: [
                    {
                        name: 'Información básica',
                        desc: '',
                        fields: [
                            {
                                name: 'tipo_identificacion',
                                label: 'Tipo identificación',
                                disabled: false,
                                type: 'select',
                                col: 3,
                                options: genericOptions.tipo_de_documento,
                                validation: validationDefault(
                                    ['required'],
                                    'tipo identificación'
                                ),
                            },
                            {
                                name: 'numero_identificacion',
                                label: 'Número de identificación',
                                disabled: false,
                                type: 'integer',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 12 },
                                        { type: 'minLength', value: 6 },
                                    ],
                                    'número de identificación'
                                ),
                            },
                            {
                                name: 'primer_nombre',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'primer nombre'
                                ),
                            },
                            {
                                name: 'segundo_nombre',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'segundo nombre'
                                ),
                            },
                            {
                                name: 'primer_apellido',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 2 },
                                    ],
                                    'primer apellido'
                                ),
                            },
                            {
                                name: 'segundo_apellido',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 2 },
                                    ],
                                    'segundo apellido'
                                ),
                            },
                            {
                                name: 'genero',
                                disabled: false,
                                type: 'select',
                                col: 3,
                                options: genericOptions.genero,
                                validation: validationDefault(
                                    ['required'],
                                    'genero'
                                ),
                            },
                            {
                                name: 'lugar_nacimiento',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciudades/',
                                validation: validationDefault(
                                    ['required'],
                                    'lugar de nacimiento'
                                ),
                            },
                            {
                                name: 'fecha_nacimiento',
                                disabled: false,
                                type: 'date',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'Fecha de nacimiento'
                                ),
                            },
                            {
                                name: 'extra_celular',
                                label: 'Celular',
                                disabled: false,
                                type: 'celular',
                                col: 3,
                                validation: validationDefaultNew(
                                    [{ type: 'required', value: true }],
                                    'celular'
                                ),
                            },
                            {
                                name: 'extra_telefono',
                                label: 'Teléfono',
                                disabled: false,
                                type: 'phone',
                                col: 3,
                            },
                            {
                                name: 'tipo_relacion',
                                label: 'Tipo relación',
                                disabled: true,
                                type: 'hidden',
                                col: 3,
                                value: 0,
                            },
                        ],
                    },
                    {
                        name: 'Información del trabajo',
                        desc: '',
                        fields: [
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 30 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'Empresa'
                                ),
                            },
                            {
                                name: 'trabajo_direccion',
                                label: 'Dirección del trabajo',
                                disabled: false,
                                type: 'address',
                                col: 6,
                            },
                            {
                                name: 'trabajo_ciudad',
                                label: 'Ciudad de trabajo',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciudades/',
                            },
                            {
                                name: 'trabajo_antiguedad',
                                label: 'Antigüedad (meses)',
                                disabled: false,
                                type: 'integer',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'max', value: 900 },
                                        { type: 'min', value: 1 },
                                    ],
                                    'antigüedad (meses)'
                                ),
                            },
                            {
                                name: 'trabajo_telefono',
                                label: 'Teléfono de la empresa',
                                disabled: false,
                                type: 'phone',
                                col: 3,
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Ingresos',
                                disabled: false,
                                type: 'price',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 12 },
                                        { type: 'minLength', value: 1 },
                                    ],
                                    'ingresos'
                                ),
                            },
                            {
                                name: 'negocio_actividad',
                                label: 'Actividad económica',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciiu/',
                            },
                        ],
                    },
                ],
                defaultValues: {
                    tipo_de_documento: 'CC',
                    numero_identificacion: '123455556',
                    primer_nombre: 'test 1',
                    segundo_nombre: 'test 2',
                    primer_apellido: 'test ape1',
                    segundo_apellido: 'test ape2',
                    genero: 'H',
                    lugar_expedicion_documento: 1205,
                    fecha_expedicion_documento: '2020-08-30',
                    lugar_nacimiento: 1205,
                    fecha_nacimiento: '2020-08-30',
                    telefono_celular: '3105436525',
                    telefono_fijo: '5555555',

                    //info-trabajo
                    empresa: 'pruebas',
                    direccion_trabajo: 'vereda  aaaa',
                    codigo_postal: '76001',
                    descripcion_actividad_economica: 'test information',
                    antiguedad: 1,
                    telefono_empresa: '3215647',
                    ingresos: '200000',
                    actividad_economica: 'Venta de jugos',
                },
            },
            codebtorCredit: {
                permission: ['codebtorCredit:list', 'codebtorCredit:edit'],
                fields: [],
            },
            codebtorInformation: {
                tittle: 'codeudor',
                fields: [
                    {
                        name: 'tipo_identificacion',
                        label: 'Tipo identificación',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_de_documento,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        name: 'numero_identificacion',
                        label: 'Número identificación',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'primer_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'segundo_nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'segundo nombre'
                        ),
                    },
                    {
                        name: 'primer_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'primer apellido'
                        ),
                    },
                    {
                        name: 'segundo_apellido',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 2 },
                            ],
                            'segundo apellido'
                        ),
                    },
                    {
                        name: 'genero',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.genero,
                        validation: validationDefault(['required'], 'genero'),
                    },
                    {
                        name: 'lugar_expedicion_documento',
                        label: 'Lugar expedición documento',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de expedición documento'
                        ),
                    },
                    {
                        name: 'fecha_expedicion_documento',
                        label: 'Fecha expedición documento',
                        disabled: false,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'fecha de expedición del documento'
                        ),
                    },
                    {
                        name: 'lugar_nacimiento',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de nacimiento'
                        ),
                    },
                    {
                        name: 'fecha_nacimiento',
                        disabled: false,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Fecha de nacimiento'
                        ),
                    },
                    {
                        name: 'tipo_relacion',
                        label: 'Tipo relación',
                        disabled: true,
                        type: 'hidden',
                        col: 3,
                        value: 1,
                    },
                ],
                socioEconomicCodebtor: {
                    fields: [
                        {
                            name: 'naturaleza_persona',
                            label: 'Tipo',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_codeudor,
                            validation: validationDefault(
                                ['required'],
                                'naturaleza'
                            ),
                            isMulti: false,
                        },
                        {
                            name: 'estado_civil',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: genericOptions.estado_civil,
                            validation: validationDefault(
                                ['required'],
                                'estado civil'
                            ),
                        },
                        {
                            name: 'tipo_vivienda',
                            disabled: false,
                            type: 'select',
                            col: 6,
                            options: genericOptions.tipo_vivienda,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'tipo vivienda'
                            ),
                        },
                        {
                            name: 'estrato',
                            disabled: false,
                            type: 'integer',
                            col: 3,
                            validation: validationDefaultNew(
                                [
                                    { type: 'max', value: 6 },
                                    { type: 'min', value: 1 },
                                ],
                                'estrato'
                            ),
                        },
                        {
                            name: 'personas_a_cargo',
                            disabled: false,
                            type: 'integer',
                            col: 3,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'max', value: 10 },
                                    { type: 'min', value: 0 },
                                ],
                                'personas a cargo'
                            ),
                        },
                        {
                            name: 'numero_hijos',
                            label: 'Número hijos',
                            disabled: false,
                            type: 'integer',
                            col: 3,

                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'max', value: 20 },
                                    { type: 'min', value: 0 },
                                ],
                                'número de hijos'
                            ),
                        },
                        {
                            name: 'escolaridad',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            options: genericOptions.escolaridad,
                            validation: validationDefault(
                                ['required'],
                                'escolaridad'
                            ),
                        },
                    ],
                    fieldsConditional: {
                        pI: [
                            {
                                name: 'negocio_actividad',
                                label: 'Actividad económica',
                                disabled: false,
                                type: 'autocomplete',
                                col: 6,
                                options: [],
                                url: '/globals/ciiu/',
                                validation: validationDefault(
                                    ['required'],
                                    'Actividad económica'
                                ),
                            },
                            {
                                name: 'negocio_descripcion',
                                label: 'Descripción actividad económica',
                                disabled: false,
                                type: 'string',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'descripción actividad económica'
                                ),
                            },
                            {
                                name: 'trabajo_antiguedad',
                                label: 'Antigüedad  (meses)',
                                disabled: false,
                                type: 'integer',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'max', value: 900 },
                                        { type: 'min', value: 1 },
                                    ],
                                    'antigüedad residencia'
                                ),
                            },
                            {
                                name: 'trabajo_nombre',
                                label: 'Nombre del negocio',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'nombre del negocio'
                                ),
                            },
                            {
                                name: 'trabajo_direccion',
                                label: 'Dirección del negocio',
                                disabled: false,
                                type: 'address',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'dirección del negocio'
                                ),
                            },
                            {
                                name: 'trabajo_telefono',
                                label: 'Teléfono del negocio',
                                disabled: false,
                                type: 'phone',
                                col: 3,
                            },
                            {
                                name: 'trabajo_celular',
                                label: 'Celular',
                                disabled: false,
                                type: 'celular',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'celular'
                                ),
                            },
                            {
                                name: 'negocio_liquidez',
                                label: 'Liquidez',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'liquidez'
                                ),
                            },
                        ],
                        pE: [
                            // EMPLEADO
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'empresa'
                                ),
                            },
                            {
                                name: 'empresa_contrato',
                                label: 'Contrato',
                                disabled: false,
                                type: 'select',
                                col: 3,
                                options: genericOptions.tipo_contrato_codeudor,
                                validation: validationDefault(
                                    ['required'],
                                    'contrato'
                                ),
                            },
                            {
                                name: 'trabajo_cargo',
                                label: 'Cargo',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'cargo'
                                ),
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Salario',
                                disabled: false,
                                type: 'price',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'salario'
                                ),
                            },
                            {
                                name: 'trabajo_direccion',
                                label: 'Dirección empresa',
                                disabled: false,
                                type: 'address',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'dirección empresa'
                                ),
                            },
                            {
                                name: 'trabajo_telefono',
                                label: 'Teléfono empresa',
                                disabled: false,
                                type: 'phone',
                                col: 4,
                            },
                            {
                                name: 'trabajo_celular',
                                label: 'Celular',
                                disabled: false,
                                type: 'celular',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'celular'
                                ),
                            },
                            {
                                name: 'trabajo_antiguedad',
                                label: 'Antigüedad empresa (meses)',
                                disabled: false,
                                type: 'integer',
                                col: 4,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'max', value: 900 },
                                        { type: 'min', value: 1 },
                                    ],
                                    'antigüedad empresa'
                                ),
                            },
                        ],
                        pP: [
                            // PENSIONADO
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'empresa'
                                ),
                            },
                            {
                                name: 'empresa_contrato',
                                label: 'Contrato',
                                disabled: false,
                                type: 'select',
                                col: 3,
                                options: genericOptions.tipo_contrato_codeudor,
                                validation: validationDefault(
                                    ['required'],
                                    'contrato'
                                ),
                            },
                            {
                                name: 'trabajo_cargo',
                                label: 'Cargo',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'cargo'
                                ),
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Salario',
                                disabled: false,
                                type: 'price',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'salario'
                                ),
                            },
                            {
                                name: 'trabajo_direccion',
                                label: 'Dirección empresa',
                                disabled: false,
                                type: 'address',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'dirección empresa'
                                ),
                            },
                            {
                                name: 'trabajo_telefono',
                                label: 'Teléfono empresa',
                                disabled: false,
                                type: 'phone',
                                col: 4,
                            },
                            {
                                name: 'trabajo_celular',
                                label: 'Celular',
                                disabled: false,
                                type: 'celular',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'celular'
                                ),
                            },
                            {
                                name: 'trabajo_antiguedad',
                                label: 'Antigüedad empresa (meses)',
                                disabled: false,
                                type: 'integer',
                                col: 4,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'max', value: 900 },
                                        { type: 'min', value: 1 },
                                    ],
                                    'antigüedad empresa'
                                ),
                            },
                        ],
                        pFR: [
                            // FINCA RAIZ
                            {
                                name: 'trabajo_cargo',
                                label: 'Ocupación',
                                disabled: false,
                                type: 'string',
                                col: 6,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'Ocupación'
                                ),
                            },
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [{ type: 'minLength', value: 3 }],
                                    'empresa'
                                ),
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Salario',
                                disabled: false,
                                type: 'price',
                                col: 3,
                            },
                        ],
                        pFS: [
                            // FIRMA SOLIDARIA
                            {
                                name: 'trabajo_cargo',
                                label: 'Ocupación',
                                disabled: false,
                                type: 'string',
                                col: 6,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'minLength', value: 5 },
                                    ],
                                    'Ocupación'
                                ),
                            },
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [{ type: 'minLength', value: 3 }],
                                    'empresa'
                                ),
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Salario',
                                disabled: false,
                                type: 'price',
                                col: 3,
                            },
                        ],
                    },
                },
                contactCodebtor: {
                    fields: [
                        {
                            name: 'ciudad_contacto',
                            label: 'Ciudad',
                            disabled: false,
                            type: 'autocomplete-dependency',
                            col: 3,
                            showTable: true,
                            options: [],
                            parentDependency: true,
                            childrenDependency: 'barrio_contacto',
                            url: '/globals/ciudades/',
                            validation: validationDefault(
                                ['required'],
                                'ciudad de contacto'
                            ),
                        },
                        {
                            name: 'barrio_contacto',
                            disabled: false,
                            type: 'select',
                            col: 3,
                            showTable: true,
                            dependency: 'ciudad_contacto',
                            options: [],
                            url: '/globals/barrios/',
                            validation: validationDefault(
                                ['required'],
                                'barrio de contacto'
                            ),
                        },
                        {
                            name: 'direccion',
                            label: 'Dirección',
                            disabled: false,
                            type: 'address',
                            col: 6,
                            showTable: true,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'maxLength', value: 255 },
                                    { type: 'minLength', value: 2 },
                                ],
                                'dirección'
                            ),
                        },
                        {
                            name: 'referencia_ubicacion',
                            label: 'Referencia ubicación',
                            disabled: false,
                            type: 'string',
                            col: 4,
                            showTable: true,
                        },
                        {
                            name: 'telefono',
                            label: 'Teléfono',
                            disabled: false,
                            type: 'phone',
                            col: 4,
                        },
                        {
                            name: 'celular',
                            label: 'Celular',
                            disabled: false,
                            type: 'celular',
                            col: 4,
                            showTable: true,
                            validation: validationDefault(
                                ['required'],
                                'celular'
                            ),
                        },
                        {
                            name: 'correo_electronico',
                            label: 'Correo electrónico',
                            disabled: false,
                            type: 'email',
                            col: 6,
                            showTable: true,
                            validation: validationDefault(
                                ['required'],
                                'Correo electrónico'
                            ),
                        },
                        {
                            name: 'principal',
                            label: 'Principal',
                            disabled: false,
                            type: 'select',
                            options: genericOptions.direccion_principal,
                            defaultValue: [],
                            col: 6,
                            showTable: true,
                        },
                    ],
                },
                codebtorConyuge: {
                    fields: [
                        {
                            name: 'Información básica',
                            desc: '',
                            fields: [
                                {
                                    name: 'tipo_identificacion',
                                    label: 'Tipo identificación',
                                    disabled: false,
                                    type: 'select',
                                    col: 3,
                                    options: genericOptions.tipo_de_documento,
                                    validation: validationDefault(
                                        ['required'],
                                        'tipo identificación'
                                    ),
                                },
                                {
                                    name: 'numero_identificacion',
                                    label: 'Número identificación',
                                    disabled: false,
                                    type: 'integer',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'required', value: true },
                                            { type: 'maxLength', value: 12 },
                                            { type: 'minLength', value: 6 },
                                        ],
                                        'número de identificación'
                                    ),
                                },
                                {
                                    name: 'primer_nombre',
                                    disabled: false,
                                    type: 'string',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'required', value: true },
                                            { type: 'maxLength', value: 25 },
                                            { type: 'minLength', value: 3 },
                                        ],
                                        'primer nombre'
                                    ),
                                },
                                {
                                    name: 'segundo_nombre',
                                    disabled: false,
                                    type: 'string',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'maxLength', value: 25 },
                                            { type: 'minLength', value: 3 },
                                        ],
                                        'segundo nombre'
                                    ),
                                },
                                {
                                    name: 'primer_apellido',
                                    disabled: false,
                                    type: 'string',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'required', value: true },
                                            { type: 'maxLength', value: 25 },
                                            { type: 'minLength', value: 2 },
                                        ],
                                        'primer apellido'
                                    ),
                                },
                                {
                                    name: 'segundo_apellido',
                                    disabled: false,
                                    type: 'string',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'maxLength', value: 25 },
                                            { type: 'minLength', value: 2 },
                                        ],
                                        'segundo nombre'
                                    ),
                                },
                                {
                                    name: 'genero',
                                    disabled: false,
                                    type: 'select',
                                    col: 3,
                                    options: genericOptions.genero,
                                    validation: validationDefault(
                                        ['required'],
                                        'genero'
                                    ),
                                },
                                {
                                    name: 'lugar_nacimiento',
                                    disabled: false,
                                    type: 'autocomplete',
                                    col: 3,
                                    options: [],
                                    url: '/globals/ciudades/',
                                    validation: validationDefault(
                                        ['required'],
                                        'lugar de nacimiento'
                                    ),
                                },
                                {
                                    name: 'fecha_nacimiento',
                                    disabled: false,
                                    type: 'date',
                                    col: 3,
                                    validation: validationDefault(
                                        ['required'],
                                        'Fecha de nacimiento'
                                    ),
                                },
                                {
                                    name: 'extra_celular',
                                    label: 'Celular',
                                    disabled: false,
                                    type: 'celular',
                                    col: 3,
                                    validation: validationDefault(
                                        ['required'],
                                        'celular'
                                    ),
                                },
                                {
                                    name: 'tipo_relacion',
                                    label: 'Tipo relación',
                                    disabled: false,
                                    type: 'hidden',
                                    col: 3,
                                    value: 2,
                                },
                            ],
                            defaultValues: {
                                tipo_de_documento: 'CC',
                                numero_identificacion: Math.floor(
                                    randomFloat(0, 1) * 10000
                                ),
                                primer_nombre: 'test 1',
                                segundo_nombre: 'test 2',
                                primer_apellido: 'test ape1',
                                segundo_apellido: 'test ape2',
                                genero: 'H',
                                lugar_expedicion_documento: 1205,
                                fecha_expedicion_documento: '2020-08-30',
                                lugar_nacimiento: 1205,
                                fecha_nacimiento: '2020-08-30',
                                telefono: '3102344556',
                                //info-trabajo
                                estado_civil: 'S',
                                estrato: 1,
                                ciuu: 10,
                                descripcion_actividad_economica:
                                    'test information',
                                antiguedad_residencia: 1,
                                personas_a_cargo: 1,
                                numero_hijos: 1,
                                escolaridad: 'PO',
                                naturaleza: 'I',
                            },
                        },
                        {
                            name: 'Información del trabajo',
                            desc: '',
                            fields: [
                                {
                                    name: 'trabajo_nombre',
                                    label: 'Empresa',
                                    disabled: false,
                                    type: 'string',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'maxLength', value: 30 },
                                            { type: 'minLength', value: 3 },
                                        ],
                                        'Empresa'
                                    ),
                                },
                                {
                                    name: 'trabajo_direccion',
                                    label: 'Dirección del trabajo',
                                    disabled: false,
                                    type: 'address',
                                    col: 6,
                                },
                                {
                                    name: 'trabajo_ciudad',
                                    label: 'Ciudad de trabajo',
                                    disabled: false,
                                    type: 'autocomplete',
                                    col: 3,
                                    options: [],
                                    url: '/globals/ciudades/',
                                },
                                {
                                    name: 'trabajo_antiguedad',
                                    label: 'Antigüedad (meses)',
                                    disabled: false,
                                    type: 'integer',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'max', value: 900 },
                                            { type: 'min', value: 1 },
                                        ],
                                        'antigüedad (meses)'
                                    ),
                                },
                                {
                                    name: 'trabajo_telefono',
                                    label: 'Teléfono de la empresa',
                                    disabled: false,
                                    type: 'phone',
                                    col: 3,
                                },
                                {
                                    name: 'empresa_salario',
                                    label: 'Ingresos',
                                    disabled: false,
                                    type: 'price',
                                    col: 3,
                                    validation: validationDefaultNew(
                                        [
                                            { type: 'maxLength', value: 12 },
                                            { type: 'minLength', value: 1 },
                                        ],
                                        'ingresos'
                                    ),
                                },
                                {
                                    name: 'negocio_actividad',
                                    label: 'Actividad económica',
                                    disabled: false,
                                    type: 'autocomplete',
                                    col: 3,
                                    options: [],
                                    url: '/globals/ciiu/',
                                },
                            ],
                        },
                    ],
                },
            },
            heritageCodebtor: {
                fields: [
                    {
                        name: 'Presupuesto familiar',
                        desc: '...',
                        fields: [
                            {
                                name: 'ingreso_principal',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'ingreso principal'
                                ),
                            },
                            {
                                name: 'ingreso_conyuge',
                                label: 'Ingresos cónyuge',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'Ingresos conyuge'
                                ),
                            },
                            {
                                name: 'otros_ingresos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'otros ingresos'
                                ),
                            },
                            {
                                name: 'gastos_familiar',
                                label: 'Gasto familiar',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'gasto familiar'
                                ),
                            },
                            {
                                name: 'obligaciones',
                                label: 'Obligaciones / cuotas',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'obligaciones / cuotas'
                                ),
                            },
                            {
                                name: 'excedente',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'excedente'
                                ),
                            },
                        ],
                    },
                    {
                        name: 'Presupuesto empresarial',
                        desc: '...',
                        fields: [
                            {
                                name: 'total_activos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total activos'
                                ),
                            },
                            {
                                name: 'total_pasivos',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total pasivo'
                                ),
                            },
                            {
                                name: 'total_patrimonio',
                                disabled: false,
                                type: 'price',
                                col: 4,
                                validation: validationDefault(
                                    ['required'],
                                    'total patrimonio'
                                ),
                            },
                        ],
                    },
                ],
                realState: [
                    {
                        name: 'Bienes raices',
                        desc: '...',
                        fields: [
                            {
                                name: 'tipo_vivienda',
                                label: 'Tipo de bien raiz',
                                disabled: false,
                                type: 'select',
                                options: genericOptions.tipo_de_bien_raiz,
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'tipo de bien raiz'
                                ),
                            },
                            {
                                name: 'direccion_bien_raiz',
                                label: 'Dirección',
                                disabled: false,
                                type: 'address',
                                col: 6,
                                showTable: true,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 255 },
                                        { type: 'minLength', value: 2 },
                                    ],
                                    'dirección'
                                ),
                            },
                            {
                                name: 'valor_bien_raiz',
                                label: 'Valor comercial',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'valor comercial'
                                ),
                            },
                        ],
                    },
                ],
                ownVehicle: [
                    {
                        name: 'Vehículo',
                        desc: '...',
                        fields: [
                            {
                                name: 'marca',
                                disabled: false,
                                type: 'select',
                                col: 6,
                                url: '/globals/marcas/',
                                validation: validationDefault(
                                    ['required'],
                                    'marca'
                                ),
                            },
                            {
                                name: 'placa',
                                disabled: false,
                                type: 'string',
                                col: 6,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 7 },
                                        { type: 'minLength', value: 6 },
                                        {
                                            type: 'pattern',
                                            value: /^[a-zA-Z0-9_.-]*$/,
                                            mesagge:
                                                'La placa solo debe tener numeros y letras',
                                        },
                                    ],
                                    'placa'
                                ),
                            },
                            {
                                name: 'modelo',
                                disabled: false,
                                type: 'integer',
                                col: 6,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 4 },
                                        { type: 'minLength', value: 4 },
                                    ],
                                    'modelo'
                                ),
                            },
                            {
                                name: 'valor_vehiculo',
                                label: 'Valor comercial',
                                disabled: false,
                                type: 'price',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'valor comercial'
                                ),
                            },
                        ],
                    },
                ],
            },
            informationClientException: {
                permissions: [],
                fields: [],
            },
            approvalCertificate: {
                permission: [],
                fields: [
                    {
                        name: 'frecuencia_pago',
                        disabled: true,
                        type: 'select',
                        options: genericOptions.frecuencia_de_pago,
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'frecuencia de pago'
                        ),
                    },
                    {
                        name: 'kiva',
                        disabled: true,
                        type: 'switch',
                        typeColumn: 2,
                        col: 2,
                        validation: validationDefault(['required'], 'kiva'),
                    },
                    {
                        name: 'recoge_saldo',
                        disabled: true,
                        type: 'switch',
                        typeColumn: 2,
                        col: 2,
                        validation: validationDefault(
                            ['required'],
                            'recoger saldo'
                        ),
                    },
                    {
                        name: 'periodo_gracia',
                        disabled: true,
                        type: 'switch',
                        col: 2,
                        typeColumn: 2,
                        validation: validationDefault(
                            ['required'],
                            'periodo de gracia'
                        ),
                    },
                    {
                        name: 'vinculo_agro',
                        label: 'Vínculo agro',
                        disabled: true,
                        type: 'switch',
                        col: 2,
                        typeColumn: 2,
                        validation: validationDefault(
                            ['required'],
                            'periodo de gracia'
                        ),
                    },
                    {
                        name: 'meses_periodo',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.meses_de_gracia,
                    },
                    {
                        name: 'excepcion',
                        label: 'Excepción',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.excepcion_no_aprobado,
                    },
                    {
                        name: 'propuesta_inicial',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.propuesta_inicial,
                    },
                    {
                        name: 'codeudores',
                        disabled: true,
                        type: 'select-multiple',
                        col: 8,
                        url: objGenericUrl.personaSolicitud,
                        reduxParams: [
                            'client.numero_identificacion',
                            'credit.id',
                        ],
                    },

                    {
                        name: 'tipo_garantia',
                        label: 'Garantia',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.garantia,
                        validation: validationDefault(['required'], 'garantía'),
                    },
                ],
                fieldsFng: [
                    {
                        name: 'fng_producto',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        url: '/globals/fng_productos/',
                        parentDependency: true,
                        childrenDependency: 'fng_porcentaje',
                        validation: validationDefault(
                            ['required'],
                            'fng producto'
                        ),
                    },
                    {
                        name: 'fng_porcentaje',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        dependency: 'fng_producto',
                        url: '/globals/fng_porcentajes/', //?fng_producto=4',
                        validation: validationDefault(
                            ['required'],
                            'fng porcentaje'
                        ),
                    },
                    {
                        name: 'fng_calificacion',
                        label: 'Fng calificación',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.calificacion,
                        validation: validationDefault(
                            ['required'],
                            'fng calificación'
                        ),
                    },
                    {
                        name: 'fng_garantia',
                        label: 'Fng garantía',
                        disabled: true,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 10 },
                                { type: 'minLength', value: 7 },
                            ],
                            'fng garantía'
                        ),
                    },
                ],
                fieldsNoApproved: [
                    {
                        name: 'causal',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.causal_no_aprobado,
                        validation: validationDefault(
                            ['required'],
                            'causal no aprobado'
                        ),
                    },
                ],
            },
            reportTeamPerformance: {
                fields: [
                    {
                        name: 'groups',
                        label: 'Rol',
                        disabled: false,
                        type: 'hidden',
                        col: 4,
                        defaultValue: { value: 4, label: 'MESA DE APROBACION' },
                    },
                    {
                        name: 'user',
                        label: 'Usuario',
                        disabled: false,
                        type: 'hidden',
                        col: 4,
                    },
                    {
                        name: 'type_group',
                        label: 'Tipo',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: [
                            { value: 0, label: 'AÑO - MES' },
                            { value: 1, label: 'MES - DIA' },
                        ],
                        defaultValue: { value: 0, label: 'AÑO - MES' },
                    },
                    {
                        name: 'year',
                        label: 'Año',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.anios_report,
                        defaultValue: defaultYearReport(),
                    },
                    {
                        name: 'month',
                        label: 'Mes',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.meses_report,
                    },
                ],
            },
        },
    },
};

export default Rules;
