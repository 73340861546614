import React from 'react';
import { NavLink } from 'react-router-dom';

export const RequestId = {
    Header: () => 'Solicitud',
    id: 'id',
    Cell: ({ row }) => {
        return (
            <NavLink
                to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                className="finamiga-color">
                {' '}
                <span className="box-state">{row.original.pk}</span>
            </NavLink>
        );
    },
};

export const RequestIdWithoutRedirect = {
    Header: () => 'Solicitud',
    id: 'id',
    Cell: ({ row }) => {
        return (
            <NavLink to="#" className="finamiga-color">
                {' '}
                <span className="box-state">{row.original.pk}</span>
            </NavLink>
        );
    },
};
