import React from 'react';

export const OfficeRequest = {
    Header: () => 'Oficina',
    id: 'regional',
    Cell: ({ row }) => {
        return (
            <>
                {row.original.oficina?.label ? (
                    <span>{row.original.oficina.label}</span>
                ) : (
                    <span>SIN ASIGNAR</span>
                )}
            </>
        );
    },
};
