import React from 'react';
import { RedirectTableCell } from '../RedirectTableCell';

export const ClientIdentification = {
    Header: () => 'Identificación',
    id: 'numero_identificacion',
    Cell: ({ row }) => {
        return (
            <RedirectTableCell
                route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                label={row.original.persona.numero_identificacion}
            />
        );
    },
};
