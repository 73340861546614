import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import WarningRounded from '@material-ui/icons/WarningRounded';

/**Utils */
import { getValueFromObjectWithString } from '../functions/General';

const INACTIVE_DAYS_LIMIT = 5;

/** fix the top position apparently cus the perfectScroll component  */

export const RequestIdTableCell = ({ row, comparativeDateField }) => {
    const inactivesDays = useMemo(
        () =>
            Math.round(
                (new Date().getTime() -
                    new Date(
                        getValueFromObjectWithString(
                            row.original,
                            comparativeDateField
                        )
                    ).getTime()) /
                    (1000 * 3600 * 24)
            ),
        [row.original, comparativeDateField]
    );

    if (inactivesDays >= INACTIVE_DAYS_LIMIT) {
        return (
            <NavLink
                className="tooltipCss finamiga-color"
                to={`process/${row.original?.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}>
                <span
                    className="box-state"
                    style={{ justifyContent: 'space-evenly' }}>
                    {row.original.pk}
                    <WarningRounded
                        style={{
                            fontSize: 'initial',
                            position: 'absolute',
                            right: '0',
                        }}
                    />
                </span>
                <span className="tooltiptext btn btn-outline-primary right">
                    {`Esta solicitud lleva ${inactivesDays} dias sin ser gestionada`}
                </span>
            </NavLink>
        );
    } else {
        return (
            <NavLink
                to={`process/${row.original?.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                className="finamiga-color">
                {' '}
                <span className="box-state">{row.original.pk}</span>
            </NavLink>
        );
    }
};
