/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const PICTURE_USER = 'PICTURE_USER';
export const USER_OFFICES = 'USER_OFFICES';
export const SET_ROL_USER = 'SET_ROL_USER';
export const SET_OLD_PASS = 'SET_OLD_PASS';
export const USER_CONCESSIONAIRE = 'USER_CONCESSIONAIRE';
export const NAV_CONTENT = 'NAV_CONTENT';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/**GLOBALS */
export const GLOBAL_SET_DATA = 'GLOBAL_SET_DATA';

/** Wizard */
export const WIZARD_SET_DATA = 'WIZARD_SET_DATA';
export const TRIGGER_SET_DATA = 'TRIGGER_SET_DATA';
export const JUMP_FIRST_STEP = 'JUMP_FIRST_STEP';
export const RESPONSABLE_LOADED = 'RESPONSABLE_LOADED';
export const ERROR_INFORMATION_CREDIT = 'ERROR_INFORMATION_CREDIT';

/*** DEPENDENCE */
export const DEPENDENCE_SET_DATA = 'DEPENDENCE_SET_DATA';

/*** VALIDATIONS */
export const VALIDATIONS_SET_DATA = 'VALIDATIONS_SET_DATA';

/*** GENERAL */
export const MODAL_REASSIGN_USER_SET_DATA = 'MODAL_REASSIGN_USER_SET_DATA';
export const MODAL_RESUME_REQUEST_SET_DATA = 'MODAL_RESUME_REQUEST_SET_DATA';
export const FILTER_DINAMIC_SET_DATA = 'FILTER_DINAMIC_SET_DATA';
export const GROUP_USER_REASING = 'GROUP_USER_REASING';
export const IS_ACTIVE_CONCESSIONAIRE = 'IS_ACTIVE_CONCESSIONAIRE';
export const LOADING_PEACE_SAFE = 'LOADING_PEACE_SAFE';
export const LOADING_GENERATE_DODUMENT = 'LOADING_GENERATE_DODUMENT';
export const SET_BREADCRUMBS_ROUTES = 'SET_BREADCRUMBS_ROUTES';
export const FILTER_DINAMIC_COMPARATIVE_MONTHS =
    'FILTER_DINAMIC_COMPARATIVE_MONTHS';
export const FILTER_DINAMIC_TEAM_PERFORMANCE =
    'FILTER_DINAMIC_TEAM_PERFORMANCE';
export const GROUP_USER_GENERAL = 'GROUP_USER_GENERAL';
export const VIEW_BUTTON_EDIT_CONTACT_CLIENT =
    'VIEW_BUTTON_EDIT_CONTACT_CLIENT';
export const NO_SHOW_COMPONENT_IN_MENU = 'NO_SHOW_COMPONENT_IN_MENU';
export const NO_SHOW_MODAL = 'NO_SHOW_MODAL';
export const CHANGE_INFO_CONTAINER = 'CHANGE_INFO_CONTAINER';
export const STATE_VERIFIK = 'STATE_VERIFIK';
export const STATE_VERIFIK_START = 'STATE_VERIFIK_START';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './globals/actions';
export * from './errors/actions';
export * from './dependencies/actions';
export * from './general/actions';

