import {
    RequestIdWithWarning,
    replaceRequestId,
} from '../../components/utilities/Table/generalColumns/RequestIdWithWarning';
import { OfficeRequest } from '../../components/utilities/Table/generalColumns/RegionalRequest';
import { ClientIdentification } from '../../components/utilities/Table/generalColumns/ClientIdentification';
import { ExecutiveWithTooltip } from '../../components/utilities/Table/generalColumns/ExecutiveWithTooltip';
import { StatusWithTooltip } from '../../components/utilities/Table/generalColumns/StatusWithTooltip';
import { CausalRequest } from '../../components/utilities/Table/generalColumns/CausalRequest';
import { DefineDate } from '../../components/utilities/Table/generalColumns/DefineDate';

const commonColumns = [
    RequestIdWithWarning('gestionsolicitud.fecha_ejecutivo'),
    {
        Header: 'Fecha Ejecutivo',
        accessor: 'gestionsolicitud.fecha_ejecutivo',
    },
    {
        Header: 'Producto',
        accessor: 'subproducto.label',
    },
    OfficeRequest,
    ClientIdentification,
    {
        Header: 'Cliente',
        accessor: 'persona.full_name',
    },
    ExecutiveWithTooltip,
];

export const requestsListStatus = [
    {
        id: 'tableBGMesaDeAprobacion',
        label: 'EN BOLSA GENERAL',
        status: 30,
        responsable: false,
        columns: commonColumns,
        buttonAction: [{ name: 'takeRequest' }],
    },
    {
        id: 'tableEPMesaDeAprobacion',
        label: 'EN PROCESO',
        status: 30,
        responsable: true,
        columns: commonColumns,
    },
    {
        id: 'tablePMesaDeAprobacion',
        label: 'PROCESADAS',
        status: null,
        nameColumnProcessed: [
            {
                name: 'estado_solicitud__gt',
                value: 30,
                operator: '%26',
            },
            {
                name: 'gestionsolicitud__usuario_mesa_aprobacion',
                value: 'user',
                operator: '|',
            },
            {
                name: 'gestionsolicitud__usuario_rechazo',
                value: 'user',
                operator: '|',
            },
            {
                name: 'gestionsolicitud__usuario_desistimiento',
                value: 'user',
                operator: '|',
            },
        ],
        responsable: false,
        columns: replaceRequestId(
            (() => {
                const cols = [...commonColumns];
                cols[1] = DefineDate('mesa_aprobacion', 'Fecha Análisis');
                cols.splice(9, 0, StatusWithTooltip);
                cols.splice(10, 0, CausalRequest);
                return cols;
            })()
        ),

        buttonAction: [{ name: 'resumeStatusRequest' }],
    },
];
