const options = {
    summaryCredit: [
        {
            label: 'Capital',
            name: 'monto',
        },
        {
            label: 'Centrales de riesgo',
            name: 'central_de_riesgo',
        },
        {
            label: 'Comision miPyme',
            name: 'comision',
        },
        {
            label: 'Garantia mobiliaria',
            name: 'garantia_mobiliaria',
        },
        {
            label: 'Gastos de desembolso',
            name: 'gastos_transaccionales',
        },
        {
            label: 'Fondo de garantias',
            name: 'fondo_garantias',
        },
        {
            label: 'Seguro agrícola',
            name: 'valor_seguro_agricola',
        },
    ],
    monthlyValues: [
        {
            label: 'Cuota mensual',
            name: 'mensualidad',
        },
        {
            label: 'Seguro de vida deudor',
            name: 'valor_seguro_deudor',
        },
        {
            label: 'Seguro voluntario',
            name: 'valor_seguro_voluntario',
        },
        {
            label: 'Seguro todo riesgo',
            name: 'valor_seguro_todo_riesgo',
        },
        {
            label: 'Seguro SBS',
            name: 'valor_seguro_sbs',
        },
    ],
};

export default options;
