import {
    monthsProduct,
    validationDefault,
    validationDefaultNew,
} from '../../components/utilities/rulesUtilities';
import genericOptions from '../defaultConfigRole/genericOptions';
import genericColumnsCalculator from '../defaultConfigRole/genericColumnsCalculator';
import requestsListStatus from './requestsListStatus';

const Rules = {
    role_8f4f037bf18d4c548fd80acf80d30c58: {
        //Ejecutivo
        home: '/credits',
        requestsListStatus,
        executive: false,
        views: {
            create: {
                titleRequestCredit: 'Solicitudes de créditos',
                permisions: ['listRequestCredit'],
                components: [
                    {
                        name: 'Solicitante',
                        component: ['InformationClient'],
                    },
                    {
                        name: 'Contacto solicitante',
                        component: ['ContactsClient'],
                    },
                    {
                        name: 'Información de la solicitud',
                        component: ['InformationCredit', 'CalculatorCredit'],
                    },
                    { name: 'Documentos', component: ['DocumentsCredit'] },
                ],
                actionStatus: ['modalAssignUser'],
            },
            consult: {
                permisions: ['consultClientList', 'ConsultClientEdit'],
                components: [
                    {
                        name: 'Solicitante',
                        component: ['InformationClient'],
                    },
                    { name: 'Contacto', component: ['ContactsClient'] },
                    { name: 'Documentos', component: ['DocumentsClient'] },
                    {
                        name: 'Solicitudes cliente',
                        component: ['RequestsClient'],
                    },
                    { name: 'Crédito', component: ['GygClient'] },
                ],
                noShowFieldsConsult: true,
            },
            profile: {
                fields: [
                    {
                        name: 'identification_number',
                        label: 'Número de identificación',
                        disabled: false,
                        type: 'integer',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'first_name',
                        label: 'Nombres',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'nombres'
                        ),
                    },
                    {
                        name: 'last_name',
                        label: 'Apellidos',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'apellidos'
                        ),
                    },
                    {
                        name: 'phone_number',
                        label: 'Número telefónico',
                        disabled: false,
                        type: 'celular',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'número telefónico'
                        ),
                    },
                ],
            },
        },
        components: {
            informationClient: {
                permisions: ['informationClient:list'],
                automaticFilters: true,
                fields: [
                    {
                        name: 'tipo_identificacion',
                        label: 'Tipo identificación',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_de_documento,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        label: 'Número de identificación',
                        name: 'numero_identificacion',
                        disabled: true,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'primer_nombre',
                        disabled: true,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'segundo_nombre',
                        disabled: true,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'segundo nombre'
                        ),
                    },
                    {
                        name: 'primer_apellido',
                        disabled: true,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer apellido'
                        ),
                    },
                    {
                        name: 'segundo_apellido',
                        disabled: true,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'segundo apellido'
                        ),
                    },
                    {
                        name: 'genero',
                        label: 'Género',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.genero,
                        validation: validationDefault(['required'], 'genero'),
                    },
                    {
                        name: 'lugar_expedicion_documento',
                        label: 'Lugar expedición documento',
                        disabled: true,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de expedición documento'
                        ),
                    },
                    {
                        name: 'fecha_expedicion_documento',
                        label: 'Fecha expedición documento',
                        disabled: true,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'fecha de expedición del documento'
                        ),
                    },
                    {
                        name: 'lugar_nacimiento',
                        disabled: true,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'lugar de nacimiento'
                        ),
                    },
                    {
                        name: 'fecha_nacimiento',
                        disabled: true,
                        type: 'date',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Fecha de nacimiento'
                        ),
                    },
                    {
                        name: 'grupo_social',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.grupo_etnico,
                    },
                ],
            },
            socioeconomicClient: {
                permisions: ['socioeconomicClient:list'],
                fields: [
                    {
                        name: 'estado_civil',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.estado_civil,
                        validation: validationDefault(
                            ['required'],
                            'estado civil'
                        ),
                    },
                    {
                        name: 'estrato',
                        disabled: true,
                        type: 'integer',
                        col: 2,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 6 },
                                { type: 'min', value: 1 },
                            ],
                            'estrato'
                        ),
                    },
                    {
                        name: 'ciiu',
                        label: 'Actividad económica',
                        disabled: true,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciiu/',
                        validation: validationDefault(
                            ['required'],
                            'Actividad económica'
                        ),
                    },
                    {
                        name: 'descripcion_actividad_economica',
                        label: 'Descripción actividad económica',
                        disabled: true,
                        type: 'string',
                        col: 4,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 230 },
                            ],
                            'descripción actividad económica'
                        ),
                    },
                    {
                        label: 'Antigüedad residencia (meses)',
                        name: 'antiguedad_residencia',
                        disabled: true,
                        type: 'integer',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 900 },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad residencia'
                        ),
                    },
                    {
                        name: 'tamano_hogar',
                        label: 'Integrantes del hogar',
                        disabled: true,
                        type: 'integer',
                        col: 2,
                        validation: validationDefaultNew(
                            [
                                { type: 'max', value: 50 },
                                { type: 'min', value: 1 },
                            ],
                            'Integrantes del hogar'
                        ),
                    },
                    {
                        name: 'personas_a_cargo',
                        disabled: true,
                        type: 'integer',
                        col: 2,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 10 },
                                { type: 'min', value: 0 },
                            ],
                            'personas a cargo'
                        ),
                    },
                    {
                        name: 'numero_hijos',
                        label: 'Número hijos',
                        disabled: true,
                        type: 'integer',
                        col: 2,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'max', value: 20 },
                                { type: 'min', value: 0 },
                            ],
                            'número de hijos'
                        ),
                    },
                    {
                        name: 'escolaridad',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.escolaridad,
                        validation: validationDefault(
                            ['required'],
                            'escolaridad'
                        ),
                    },
                    {
                        name: 'naturaleza',
                        disabled: true,
                        type: 'hidden',
                        col: 3,
                        // validation: validationDefault(
                        //     ['required'],
                        //     'naturaleza'
                        // ),
                        options: [
                            {
                                value: 'I',
                                label: 'Independiente',
                                fields: [
                                    {
                                        name: 'tipo_de_negocio',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                {
                                                    type: 'maxLength',
                                                    value: 255,
                                                },
                                                { type: 'minLength', value: 2 },
                                            ],
                                            'segundo apellido'
                                        ),
                                    },
                                    {
                                        name: 'nombre_negocio',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                {
                                                    type: 'maxLength',
                                                    value: 15,
                                                },
                                                { type: 'minLength', value: 3 },
                                            ],
                                            'nombre negocio'
                                        ),
                                    },
                                    {
                                        name: 'experiencia',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                {
                                                    type: 'maxLength',
                                                    value: 255,
                                                },
                                                { type: 'minLength', value: 2 },
                                            ],
                                            'experiencia'
                                        ),
                                    },
                                    {
                                        name: 'antiguedad',
                                        label: 'Antigüedad',
                                        disabled: false,
                                        type: 'integer',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                { type: 'min', value: 1 },
                                            ],
                                            'antigüedad'
                                        ),
                                    },
                                    {
                                        name: 'actividad',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                {
                                                    type: 'maxLength',
                                                    value: 255,
                                                },
                                                { type: 'minLength', value: 2 },
                                            ],
                                            'actividad'
                                        ),
                                    },
                                    {
                                        label: 'Descripción',
                                        name: 'descripcion',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                { type: 'minLength', value: 2 },
                                            ],
                                            'descripción'
                                        ),
                                    },
                                    {
                                        name: 'ingresos_aproximados',
                                        disabled: false,
                                        type: 'integer',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [{ type: 'required', value: true }],
                                            'segundo apellido'
                                        ),
                                    },
                                    {
                                        name: 'sector_economico',
                                        label: 'Sector económico',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                {
                                                    type: 'maxLength',
                                                    value: 255,
                                                },
                                                { type: 'minLength', value: 2 },
                                            ],
                                            'sector económico'
                                        ),
                                    },
                                    {
                                        name: 'numero_de_empleados_permanentes',
                                        label: 'Número de empleados permanentes',
                                        disabled: false,
                                        type: 'integer',
                                        col: 3,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                { type: 'min', value: 0 },
                                            ],
                                            'número de empleados permanentes'
                                        ),
                                    },
                                    {
                                        name: 'numero_de_empleados_temporales',
                                        label: 'Número de empleados temporales',
                                        disabled: false,
                                        type: 'integer',
                                        col: 3,
                                        validation: validationDefaultNew(
                                            [
                                                {
                                                    type: 'required',
                                                    value: true,
                                                },
                                                { type: 'min', value: 1 },
                                            ],
                                            'número de empleados temporales'
                                        ),
                                    },
                                    {
                                        name: 'tipo_local',
                                        disabled: false,
                                        type: 'string',
                                        col: 2,
                                        validation: validationDefaultNew(
                                            [{ type: 'required', value: true }],
                                            'tipo de local'
                                        ),
                                    },
                                ],
                            },
                            // {
                            // 	value: "E",
                            // 	label: "Empleado",
                            // 	fields: [{
                            // 			name: "nombre_empresa",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "ingresos",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "actividad_economica",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "cargo",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "antiguedad",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "tipo_contrato",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 	]
                            // },
                            // {
                            // 	value: "J",
                            // 	label: "Jubilado",
                            // 	fields: [{
                            // 			name: "nombre_empresa",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 		{
                            // 			name: "ingresos",
                            // 			disabled: false,
                            // 			type: "string",
                            // 			col: 2
                            // 		},
                            // 	]
                            // },
                        ],
                        isMulti: false,
                    },
                ],
            },
            contactsClient: {
                permisions: ['contactsClient:list'],
                tittle: 'solicitante',
                fields: [
                    {
                        name: 'ciudad_contacto',
                        label: 'Ciudad',
                        disabled: true,
                        type: 'autocomplete',
                        col: 3,
                        showTable: true,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'ciudad de contacto'
                        ),
                    },
                    {
                        name: 'barrio',
                        disabled: true,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 255 },
                                { type: 'minLength', value: 2 },
                            ],
                            'barrio'
                        ),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección',
                        disabled: true,
                        type: 'address',
                        col: 6,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 255 },
                                { type: 'minLength', value: 2 },
                            ],
                            'dirección'
                        ),
                    },
                    {
                        name: 'referencia_ubicacion',
                        label: 'Referencia ubicación',
                        disabled: true,
                        type: 'string',
                        col: 4,
                        showTable: true,
                    },
                    {
                        name: 'tipo_vivienda',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.tipo_vivienda,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'tipo vivienda'
                        ),
                    },
                    {
                        name: 'telefono',
                        label: 'Teléfono',
                        disabled: true,
                        type: 'phone',
                        col: 4,
                    },
                    {
                        name: 'celular',
                        label: 'Celular',
                        disabled: true,
                        type: 'celular',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(['required'], 'celular'),
                    },
                    {
                        name: 'correo_electronico',
                        label: 'Correo electrónico',
                        disabled: true,
                        type: 'email',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'Correo electrónico'
                        ),
                    },
                    {
                        name: 'principal',
                        label: 'Principal',
                        disabled: true,
                        type: 'select',
                        options: genericOptions.direccion_principal,
                        col: 4,
                        showTable: true,
                    },
                ],
                defaultValues: {
                    direccion: 'carrera 1 calle 1 # 1',
                    barrio: 'Ciudad modelo',
                    latitud: '11111324',
                    longitud: '38947239',
                    telefono: '',
                    celular: '',
                    correo_electronico: 'test@finamiga.co',
                    referencia_ubicacion: 'cerca a... diagonal a...',
                    tipo_ubicacion: 1,
                    departamento: 5,
                    ciudad_contacto: 1205,
                },
            },
            informationCredit: {
                permisions: ['informationCredit:list'],
                fields: [
                    {
                        name: 'subproducto',
                        disabled: true,
                        type: 'select',
                        col: 6,
                        options: [],
                        parentDependency: true,
                        url: '/globals/subproducto/',
                        validation: validationDefault(
                            ['required'],
                            'subproducto'
                        ),
                    },
                    {
                        name: 'oficina',
                        disabled: true,
                        type: 'select',
                        col: 6,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'ejecutivo',
                        url: '/globals/oficinas/',
                        validation: validationDefault(['required'], 'oficina'),
                    },
                    {
                        name: 'origen',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.origen,
                        validation: validationDefault(['required'], 'origen'),
                    },
                    {
                        name: 'medio',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.medio,
                        validation: validationDefault(['required'], 'medio'),
                    },
                    {
                        name: 'tipo_credito',
                        label: 'Tipo crédito',
                        disabled: true,
                        type: 'select',
                        col: 4,
                        options: genericOptions.tipo_credito,
                        validation: validationDefault(
                            ['required'],
                            'tipo de crédito'
                        ),
                    },
                    {
                        label: 'Seguro SBS',
                        name: 'tipo_seguro',
                        disabled: true,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_seguro_sbs,
                        validation: validationDefault(
                            ['required'],
                            'seguro sbs'
                        ),
                    },
                ],
                fieldsDynamic: [],
                defaultValues: {
                    oficina: 9,
                    producto: 17,
                    origen: 1,
                    medio: 1,
                    tipo_credito: 1,
                    monto_solicitado: 5000000,
                    plazo_solicitado: 2,
                    cuota_inicial_solicitada: 1500000,
                    asesor: 'asesor test',
                    telefono_asesor: '123456798',
                    concesionario: 'concesionario test',
                    marca: 'Bajaj',
                    linea: 'Pulsar',
                    valor: 8000000,
                    soat: 480000,
                    impuestos: 120000,
                    matricula: 200000,
                    nombre_concesionario: 'concesionario test!',
                    tipo_motocicleta: 'motocicleta',
                },
            },
            productCredit: {
                permisions: ['productCredit:list'],
                fields: [],
                filedsConditional: {
                    p2: [
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocicleta,
                            defaultValue: {
                                label: 'MOTOCICLETA',
                                value: 0,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                    ],
                    p3: [
                        // Prestamiga
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(3),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p8: [
                        // Prestamiga Preferencial
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(8),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p10: [
                        // Prestamiga Renovacion
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(10),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p18: [
                        // Prestamiga Reactivacion
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(18),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p6: [
                        // Cultiva
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(6),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p12: [
                        // Cultiva renovacion
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(12),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p14: [
                        // Cultiva preferencial
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 4,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 4,
                            options: monthsProduct(14),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'destino_recursos',
                            disabled: true,
                            type: 'string',
                            col: 4,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'minLength', value: 5 },
                                ],
                                'destino de los recursos'
                            ),
                        },
                    ],
                    p17: [
                        // Vehiculos
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'linea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo de vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_vehiculo,
                            validation: validationDefault(
                                ['required'],
                                'tipo de vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matricula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(17),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                    ],
                    p40: [
                        // Vehiculos usados
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'linea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo de vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_vehiculo,
                            validation: validationDefault(
                                ['required'],
                                'tipo de vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matricula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(17),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                        {
                            name: 'fasecolda',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'fasecolda'
                            ),
                        },
                    ],
                    p41: [
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocicleta,
                            defaultValue: {
                                label: 'MOTOCICLETA',
                                value: 0,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                    ],
                    p42: [
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocarro,
                            defaultValue: {
                                label: 'MOTOCARRO',
                                value: 1,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                    ],
                    p43: [
                        {
                            name: 'concesionario',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            url: '/globals/concesionarios/?is_active=true',
                            validation: validationDefault(
                                ['required'],
                                'concesionario'
                            ),
                        },
                        {
                            name: 'marca',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            parentDependency: true,
                            dependency: 'producto',
                            childrenDependency: 'linea',
                            url: '/globals/marcas/',
                            validation: validationDefault(
                                ['required'],
                                'marca'
                            ),
                        },
                        {
                            name: 'linea',
                            label: 'Línea',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            dependency: 'marca',
                            url: '/globals/marcas_lineas/',
                            validation: validationDefault(
                                ['required'],
                                'línea'
                            ),
                        },
                        {
                            name: 'tipo_vehiculo',
                            label: 'Tipo vehículo',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: genericOptions.tipo_motocarro,
                            defaultValue: {
                                label: 'MOTOCARRO',
                                value: 1,
                            },
                            validation: validationDefault(
                                ['required'],
                                'tipo vehículo'
                            ),
                        },
                        {
                            name: 'valor',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'valor'
                            ),
                        },
                        {
                            name: 'soat',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(['required'], 'soat'),
                        },
                        {
                            name: 'impuestos',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'impuestos'
                            ),
                        },
                        {
                            name: 'matricula',
                            label: 'Matrícula',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'matrícula'
                            ),
                        },
                        {
                            name: 'cuota_inicial_solicitada',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'cuota inicial solicitada'
                            ),
                        },
                        {
                            name: 'monto_solicitado',
                            disabled: true,
                            type: 'price',
                            col: 3,
                            validation: validationDefault(
                                ['required'],
                                'monto solicitado'
                            ),
                        },
                        {
                            name: 'plazo_solicitado',
                            disabled: true,
                            type: 'select',
                            col: 3,
                            options: monthsProduct(2),
                            validation: validationDefault(
                                ['required'],
                                'plazo solicitado'
                            ),
                        },
                    ],
                },
            },
            documentsCredit: {
                permisions: ['documentsCredit:list'],
                fields: [
                    {
                        name: 'tipo_documento',
                        disabled: true,
                        type: 'select',
                        col: 5,
                        options: genericOptions.tipo_de_adjunto,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo de documento'
                        ),
                    },
                    {
                        name: 'documento',
                        disabled: true,
                        type: 'file',
                        col: 5,
                        validation: validationDefault(
                            ['required'],
                            'documento'
                        ),
                    },
                ],
            },
            observationCredit: {
                permisions: ['observationCredit:list'],
                fields: [
                    {
                        className: 'input-observacion',
                        name: 'observacion',
                        label: 'Observación',
                        disabled: true,
                        type: 'string',
                        rows: 1,
                        typeColumn: 2,
                        col: 12,
                        showTable: true,
                        staticCol: 12,
                        validation: validationDefault(
                            ['required'],
                            'observación'
                        ),
                    },
                ],
            },
            calculatorCredit: {
                permisions: [
                    'calculatorCredit:list',
                    'calculatorCredit:edit',
                    'calculatorCredit:simulator',
                ],
                summaryCredit: genericColumnsCalculator.summaryCredit,
                monthlyValues: genericColumnsCalculator.monthlyValues,
                defaultValues: {},
                fields: [
                    {
                        name: 'subproducto',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        options: [],
                        parentDependency: true,
                        url: '/globals/subproducto/',
                        validation: validationDefault(
                            ['required'],
                            'subproducto'
                        ),
                    },
                ],
            },
            ReferencesCredit: {
                fields: [
                    {
                        name: 'primer_nombre',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer nombre'
                        ),
                    },
                    {
                        name: 'primer_apellido',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'primer apellido'
                        ),
                    },
                    {
                        name: 'segundo_apellido',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'maxLength', value: 25 },
                                { type: 'minLength', value: 3 },
                            ],
                            'segundo apellido'
                        ),
                    },
                    {
                        name: 'ciudad',
                        label: 'Ciudad',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(['required'], 'ciudad'),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección',
                        disabled: false,
                        type: 'address',
                        col: 6,
                        validation: validationDefault(
                            ['required'],
                            'dirección'
                        ),
                        showTable: true,
                    },
                    {
                        name: 'barrio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefault(['required'], 'barrio'),
                        showTable: true,
                    },
                    {
                        name: 'celular',
                        disabled: false,
                        type: 'celular',
                        col: 3,
                        validation: validationDefault(['required'], 'celular'),
                    },
                    {
                        name: 'estado_referencia',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.estado_referencia,
                        validation: validationDefault(
                            ['required'],
                            'estado referencia'
                        ),
                    },
                    {
                        name: 'parentesco',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_parentesco,
                        validation: validationDefault(
                            ['required'],
                            'parentesco'
                        ),
                    },
                    {
                        name: 'tipo_referencia',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.tipo_referencia,
                        validation: validationDefault(
                            ['required'],
                            'tipo referencia'
                        ),
                    },
                    {
                        name: 'actividad_economica',
                        label: 'Actividad económica',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'actividad economica'
                        ),
                    },
                    {
                        name: 'antiguedad',
                        label: 'Antigüedad',
                        disabled: false,
                        type: 'integer',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'antigüedad'
                        ),
                    },
                    {
                        name: 'conoce',
                        disabled: false,
                        type: 'switch',
                        col: 2,
                        typeColumn: 2,
                    },
                    {
                        name: 'recomendaria',
                        label: 'Recomendaría',
                        disabled: false,
                        type: 'switch',
                        col: 2,
                        typeColumn: 2,
                    },
                    {
                        name: 'observacion',
                        label: 'Observación',
                        disabled: false,
                        type: 'textarea',
                        col: 12,
                        validation: validationDefault(
                            ['required'],
                            'observación'
                        ),
                    },
                ],
                defaultValues: {
                    primer_nombre: 'pruebas1',
                    primer_apellido: 'sistemas',
                    segundo_apellido: 'sistemas 2',
                    celular: '3249872938',
                    direccion: 'carrera 1 calle 1 # 1',
                    barrio: 'Ciudad modelo',
                    estado_referencia: 1,
                    parentesco: 0,
                    tipo_referencia: 0,
                    conoce: true,
                    recomendaria: false,
                    actividad_economica: 'venta de pollos',
                    observacion: 'ninguna',
                    antiguedad: 2,
                },
            },
            analysisCredit: {
                permisions: [],
                fields: [],
            },
            Ppi: {
                permission: ['Ppi:list' /**'Ppi:edit'**/],
                fields: [
                    {
                        id: '1',
                        question: '¿Cuántos miembros tiene el hogar?',
                        options: [
                            {
                                response: 'A. 2 o menos',
                                points: 20,
                                idQuestions: 'ppi_1_1',
                            },
                            {
                                response: 'B. 3 a 4',
                                points: 10,
                                idQuestions: 'ppi_1_2',
                            },
                            {
                                response: 'C. 5 a 6',
                                points: 3,
                                idQuestions: 'ppi_1_3',
                            },
                            {
                                response: 'D. 7 o más',
                                points: 0,
                                idQuestions: 'ppi_1_4',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_1',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 1'
                        ),
                    },
                    {
                        id: '2',
                        question:
                            '¿Todos los niños entre los 6 y 12 años actualmente asisten al preescolar o escuela?',
                        options: [
                            {
                                response:
                                    'A. Al menos un niño entre 6 y 12 años no asiste',
                                points: 0,
                                idQuestions: 'ppi_2_1',
                            },
                            {
                                response: 'B. No hay niños entre 6 y 12 años',
                                points: 7,
                                idQuestions: 'ppi_2_2',
                            },
                            {
                                response:
                                    'C. Todos los niños entre 6 y 12 años asisten',
                                points: 1,
                                idQuestions: 'ppi_2_3',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_2',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 2'
                        ),
                    },
                    {
                        id: '3',
                        question:
                            '¿Algún miembro de este hogar alcanzó un nivel educativo superior o universitario?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 6,
                                idQuestions: 'ppi_3_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_3_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_3',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 3'
                        ),
                    },
                    {
                        id: '4',
                        question:
                            '¿Algún miembro de este hogar tiene un trabajo con un contrato escrito?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 15,
                                idQuestions: 'ppi_4_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_4_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_4',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 4'
                        ),
                    },
                    {
                        id: '5',
                        question:
                            '¿Algún miembro de este hogar es un obrero o empleado de empresa particular/privada?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 9,
                                idQuestions: 'ppi_5_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_5_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_5',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 5'
                        ),
                    },
                    {
                        id: '6',
                        question:
                            '¿Algún miembro de este hogar es un patrón o empleador?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 15,
                                idQuestions: 'ppi_6_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_6_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_6',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 6'
                        ),
                    },
                    {
                        id: '7',
                        question:
                            '¿Este hogar tiene una máquina lavadora de ropa?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 6,
                                idQuestions: 'ppi_7_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_7_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_7',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 7'
                        ),
                    },
                    {
                        id: '8',
                        question: '¿Este hogar tiene un horno microondas?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 8,
                                idQuestions: 'ppi_8_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_8_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_8',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 8'
                        ),
                    },
                    {
                        id: '9',
                        question: '¿Este hogar tiene un carro particular?',
                        options: [
                            {
                                response: 'A. Sí',
                                points: 5,
                                idQuestions: 'ppi_9_1',
                            },
                            {
                                response: 'B. No',
                                points: 0,
                                idQuestions: 'ppi_9_2',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_9',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 9'
                        ),
                    },
                    {
                        id: '10',
                        question:
                            '10 ¿Cuál es el material predominante de los pisos de la vivienda?',
                        options: [
                            {
                                response: 'A. Tierra, arena',
                                points: 0,
                                idQuestions: 'ppi_10_1',
                            },
                            {
                                response: 'B. Cemento, gravilla',
                                points: 2,
                                idQuestions: 'ppi_10_2',
                            },
                            {
                                response:
                                    'C. Madera burda, tabla, tablón, otro vegetal',
                                points: 0,
                                idQuestions: 'ppi_10_3',
                            },
                            {
                                response:
                                    'D. Baldosín, ladrillo, vinisol, otros materiales sintéticos',
                                points: 9,
                                idQuestions: 'ppi_10_4',
                            },
                            {
                                response:
                                    'E. Mármol, madera pulida, alfombra o tapete de pared a pared',
                                points: 6,
                                idQuestions: 'ppi_10_5',
                            },
                        ],
                        type: 'radio',
                        name: 'ppi_10',
                        disabled: true,
                        validation: validationDefault(
                            ['required'],
                            'Pregunta 10'
                        ),
                    },
                ],
            },
            BusinessInformationCredit: {
                permisions: ['businessInformationCredit:list'],
                fields: [
                    {
                        name: 'tipo_negocio',
                        label: 'Tipo de negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo de negocio'
                        ),
                    },
                    {
                        name: 'nombre',
                        label: 'Nombre del negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'minLength', value: 5 },
                            ],
                            'nombre del negocio'
                        ),
                    },
                    {
                        name: 'antiguedad',
                        label: 'Antigüedad de negocio (meses)',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad de negocio'
                        ),
                    },
                    {
                        name: 'antiguedad_local',
                        label: 'Antigüedad de negocio en local (meses)',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        showTable: true,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'min', value: 1 },
                            ],
                            'antigüedad de negocio en local'
                        ),
                    },
                    {
                        name: 'negocio_ciiu',
                        label: 'Actividad del negocio',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciiu/',
                        validation: validationDefault(
                            ['required'],
                            'Actividad del negocio'
                        ),
                    },
                    {
                        name: 'descripcion_actividad',
                        label: 'Descripción de la actividad del negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 230 },
                            ],
                            'descripción de la actividad del negocio'
                        ),
                    },
                    {
                        name: 'telefono',
                        label: 'Teléfono del negocio',
                        disabled: false,
                        type: 'phone',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'teléfono del negocio'
                        ),
                    },
                    {
                        name: 'celular',
                        label: 'Celular',
                        disabled: false,
                        type: 'celular',
                        col: 3,
                        validation: validationDefault(['required'], 'celular'),
                    },
                    {
                        name: 'ciudad_negocio',
                        label: 'Ciudad del negocio',
                        disabled: false,
                        type: 'autocomplete',
                        col: 3,
                        options: [],
                        url: '/globals/ciudades/',
                        validation: validationDefault(
                            ['required'],
                            'ciudad del negocio'
                        ),
                    },
                    {
                        name: 'barrio',
                        label: 'Barrio del negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'minLength', value: 3 },
                            ],
                            'barrio del negocio'
                        ),
                    },
                    {
                        name: 'direccion',
                        label: 'Dirección del negocio',
                        disabled: false,
                        type: 'address',
                        col: 6,
                        validation: validationDefault(
                            ['required'],
                            'dirección del negocio'
                        ),
                    },
                    {
                        name: 'referencia_ubicacion',
                        label: 'Referencia para ubicación del negocio',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'minLength', value: 5 },
                            ],
                            'referencia para ubicación del negocio'
                        ),
                    },
                    {
                        name: 'sector_economico',
                        label: 'Sector económico del negocio',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        options: genericOptions.sector_economico,
                        validation: validationDefault(
                            ['required'],
                            'sector económico del negocio'
                        ),
                    },
                    {
                        name: 'tipo_local',
                        label: 'Tipo de local',
                        disabled: false,
                        type: 'select',
                        col: 2,
                        options: genericOptions.tipo_local,
                        validation: validationDefault(
                            ['required'],
                            'tipo de local del negocio'
                        ),
                    },
                    {
                        name: 'empleados_permanentes',
                        label: 'Empleados permanentes',
                        disabled: false,
                        type: 'integer',
                        col: 2,
                        validation: validationDefault(
                            ['required'],
                            'empleados permanentes del negocio'
                        ),
                    },
                    {
                        name: 'empleados_temporales',
                        label: 'Empleados temporales',
                        disabled: false,
                        type: 'integer',
                        col: 2,
                        validation: validationDefault(
                            ['required'],
                            'empleados temporales del negocio'
                        ),
                    },
                    {
                        name: 'rut',
                        label: 'RUT',
                        disabled: false,
                        type: 'checkbox',
                        col: 1,
                        typeColumn: 3,
                    },
                    {
                        name: 'camara_comercio',
                        label: 'Cámara de comercio',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'registra_compras',
                        label: 'Registra compras',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'registra_ventas',
                        label: 'Registra ventas',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'eps',
                        label: 'EPS',
                        disabled: false,
                        type: 'checkbox',
                        col: 1,
                        typeColumn: 3,
                    },
                    {
                        name: 'sisben',
                        label: 'SISBEN',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                    {
                        name: 'responsable_iva',
                        label: 'Responsable de IVA',
                        disabled: false,
                        type: 'checkbox',
                        col: 2,
                        typeColumn: 3,
                    },
                ],
                defaultValues: {
                    tipo_negocio: 'venta de papa',
                    nombre: 'papaMania',
                    antiguedad: 8,
                    antiguedad_local: 5,
                    //negocio_ciiu: { value: 10, label: 'Cultivo de cafa' },
                    // negocio_ciiu: {
                    //     value: 10,
                    //     label: 'Cultivo de cafa',
                    // },
                    descripcion_actividad: 'venta de papa',
                    telefono: '7230000',
                    celular: '3164907501',
                    direccion: 'calle  8norte  13a  45',
                    //ciudad_negocio: 1329,
                    // ciudad_negocio: {
                    //     value: 2208,
                    //     label: 'CALI',
                    // },
                    barrio: 'pruebas',
                    referencia_ubicacion: 'en la esquina cruza',
                    sector_economico: 0,
                    tipo_local: 0,
                    empleados_permanentes: 1,
                    empleados_temporales: 1,
                    // rut: true,
                    // camara_comercio: true,
                    // registra_compras: true,
                    // registra_ventas: true,
                    // eps: true,
                    // sisben: false,
                },
            },
            conyugeClient: {
                permisions: ['conyugeClient:list', 'conyugeClient:edit'],
                fields: [
                    {
                        name: 'Información basica',
                        desc: '',
                        fields: [
                            {
                                name: 'primer_nombre',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'primer nombre'
                                ),
                            },
                            {
                                name: 'segundo_nombre',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'segundo nombre'
                                ),
                            },
                            {
                                name: 'primer_apellido',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'primer apellido'
                                ),
                            },
                            {
                                name: 'segundo_apellido',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'maxLength', value: 25 },
                                        { type: 'minLength', value: 3 },
                                    ],
                                    'segundo apellido'
                                ),
                            },
                            {
                                name: 'genero',
                                disabled: false,
                                type: 'select',
                                col: 4,
                                options: genericOptions.genero,
                                validation: validationDefault(
                                    ['required'],
                                    'genero'
                                ),
                            },
                            {
                                name: 'tipo_identificacion',
                                label: 'Tipo identificación',
                                disabled: false,
                                type: 'select',
                                col: 4,
                                options: genericOptions.tipo_de_documento,
                                validation: validationDefault(
                                    ['required'],
                                    'tipo de documento'
                                ),
                            },
                            {
                                name: 'numero_identificacion',
                                label: 'Número de identificación',
                                disabled: false,
                                type: 'integer',
                                col: 4,
                                validation: validationDefaultNew(
                                    [
                                        { type: 'required', value: true },
                                        { type: 'maxLength', value: 12 },
                                        { type: 'minLength', value: 6 },
                                    ],
                                    'número de identificación'
                                ),
                            },
                            {
                                name: 'lugar_nacimiento',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciudades/',
                                validation: validationDefault(
                                    ['required'],
                                    'lugar de nacimiento'
                                ),
                            },
                            {
                                name: 'fecha_nacimiento',
                                disabled: false,
                                type: 'date',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'Fecha de nacimiento'
                                ),
                            },
                            {
                                name: 'extra_celular',
                                label: 'Celular',
                                disabled: false,
                                type: 'celular',
                                col: 3,
                                validation: validationDefaultNew(
                                    [{ type: 'required', value: true }],
                                    'celular'
                                ),
                            },
                            {
                                name: 'extra_telefono',
                                label: 'Teléfono fijo',
                                disabled: false,
                                type: 'phone',
                                col: 3,
                            },
                            {
                                name: 'tipo_relacion',
                                label: 'Tipo relación',
                                disabled: true,
                                type: 'hidden',
                                col: 3,
                                value: 0,
                            },
                        ],
                    },
                    {
                        name: 'Información del trabajo',
                        desc: '',
                        fields: [
                            {
                                name: 'trabajo_nombre',
                                label: 'Empresa',
                                disabled: false,
                                type: 'string',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'Empresa'
                                ),
                            },
                            {
                                name: 'trabajo_direccion',
                                label: 'Dirección del trabajo',
                                disabled: false,
                                type: 'address',
                                col: 6,
                                validation: validationDefault(
                                    ['required'],
                                    'dirección del trabajo'
                                ),
                            },
                            {
                                name: 'trabajo_ciudad',
                                label: 'Ciudad de trabajo',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciudades/',
                                validation: validationDefault(
                                    ['required'],
                                    'ciudad de trabajo'
                                ),
                            },
                            {
                                name: 'trabajo_antiguedad',
                                label: 'Antigüedad (meses)',
                                disabled: false,
                                type: 'integer',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'antigüedad (meses)'
                                ),
                            },
                            {
                                name: 'trabajo_telefono',
                                label: 'Teléfono de la empresa',
                                disabled: false,
                                type: 'phone',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'teléfono de la empresa'
                                ),
                            },
                            {
                                name: 'empresa_salario',
                                label: 'Ingresos',
                                disabled: false,
                                type: 'price',
                                col: 3,
                                validation: validationDefault(
                                    ['required'],
                                    'ingresos'
                                ),
                            },
                            {
                                name: 'negocio_actividad',
                                label: 'Actividad económica',
                                disabled: false,
                                type: 'autocomplete',
                                col: 3,
                                options: [],
                                url: '/globals/ciiu/',
                                validation: validationDefault(
                                    ['required'],
                                    'Actividad económica'
                                ),
                            },
                        ],
                    },
                ],
                defaultValues: {
                    tipo_de_documento: 'CC',
                    numero_identificacion: '123455556',
                    primer_nombre: 'test 1',
                    segundo_nombre: 'test 2',
                    primer_apellido: 'test ape1',
                    segundo_apellido: 'test ape2',
                    genero: 'H',
                    lugar_expedicion_documento: 1205,
                    fecha_expedicion_documento: '2020-08-30',
                    lugar_nacimiento: 1205,
                    fecha_nacimiento: '2020-08-30',
                    telefono_celular: '3105436525',
                    telefono_fijo: '5555555',

                    //info-trabajo
                    empresa: 'pruebas',
                    direccion_trabajo: 'vereda  aaaa',
                    codigo_postal: '76001',
                    descripcion_actividad_economica: 'test information',
                    antiguedad: 1,
                    telefono_empresa: '3215647',
                    ingresos: '200000',
                    actividad_economica: 'Venta de jugos',
                },
            },
            filterDataTable: {
                permisions: ['filterDataTable'],
                fields: [
                    {
                        name: 'oficina',
                        disabled: false,
                        type: 'select-multiple',
                        col: 3,
                        options: [],
                        parentDependency: true,
                        childrenDependency: 'ejecutivo',
                        url: `/users/{0}/oficinas/`,
                        reduxParams: ['authUser.user'],
                    },
                    {
                        name: 'ejecutivo',
                        disabled: false,
                        type: 'select-multiple',
                        col: 3,
                        dependency: 'oficinas',
                        url: '/globals/users/?groups=3&is_active=true',
                        notSearchGlobals: true,
                    },
                    {
                        name: 'created_at__gte',
                        label: 'Fecha inicial',
                        disabled: false,
                        type: 'date',
                        col: 3,
                    },
                    {
                        name: 'created_at__lte',
                        label: 'Fecha final',
                        disabled: false,
                        type: 'date',
                        col: 3,
                    },
                ],
            },
            reassingUserRequest: {
                fields: [
                    {
                        name: 'usuario',
                        label: 'Ejecutivos',
                        disabled: false,
                        type: 'select',
                        col: 12,
                        options: [],
                        dependency: 'oficinas',
                        url: '/globals/users/?groups=3&is_active=true',
                        //notSearchGlobals: true,
                    },
                    {
                        name: 'tipo_cambio_responsable',
                        disabled: false,
                        type: 'hidden',
                        col: 3,
                        value: 2,
                    },
                ],
            },
            informationClientException: {
                permissions: [],
                fields: [],
            },
        },
    },
};

export default Rules;
