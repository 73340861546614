import menuAdministrator from './menu/menuAdministrator';
import menuMesaAprobacion from './menu/menuMesaAprobacion';
import menuZoneManager from './menu/menuZoneManager';
import menuMesaDeAyuda from './menu/menuMesaDeAyuda';

export default Object.assign(
    [],
    menuAdministrator,
    menuMesaAprobacion,
    menuZoneManager,
    menuMesaDeAyuda,
);
