import React from 'react';
import { TooltipTableCell } from '../TooltipTableCell';

export const DefineDate = (rol, titleHeader = 'Fecha Gestión') => {
    return {
        Header: () => titleHeader,
        id: 'gestionsolicitud-def',
        Cell: ({ row }) => (
            <TooltipTableCell
                label={
                    row.original.estado_solicitud.label === 'RECHAZADA'
                        ? row.original.gestionsolicitud.fecha_rechazo
                        : row.original.estado_solicitud.label === 'DESISTIDA'
                        ? row.original.gestionsolicitud.fecha_desistimiento
                        : row.original.gestionsolicitud['fecha_' + rol]
                        ? row.original.gestionsolicitud['fecha_' + rol]
                        : row.original.gestionsolicitud.fecha_creacion
                }
            />
        ),
    };
};
