import React from 'react';
import { TooltipTableCell } from '../TooltipTableCell';

export const ExecutiveWithTooltip = {
    Header: () => 'Ejecutivo',
    id: 'ejecutivo',
    Cell: ({ row }) => (
        <TooltipTableCell
            label={row.original?.gestionsolicitud?.usuario_ejecutivo?.name}
            tooltipText={
                row.original?.gestionsolicitud?.usuario_ejecutivo?.email
            }
            emptyText="SIN ASIGNAR"
        />
    ),
};
