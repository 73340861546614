import React from 'react';

export const CausalRequest = {
    Header: () => 'Causal',
    id: 'causa_id',
    Cell: ({ row }) => {
        return (
            <span>{row.original.causal ? row.original.causal.label : ''}</span>
        );
    },
};
