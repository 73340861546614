import {
    validationDefault,
    validationDefaultNew,
} from '../components/utilities/rulesUtilities';
import genericOptions from './defaultConfigRole/genericOptions';

const Rules = {
    role_b0f7c326f7a54c1fa6f3a48454f2c95f: {
        //Administrador
        home: '/gogo',
        executive: false,
        views: {
            create: {
                titleRequestCredit: 'Recargas',
                permisions: ['listRequestCredit'],

                components: [
                    {
                        name: 'Recargas',
                        component: ['FormRecharge'],
                    },
                ],
            },
            profile: {
                fields: [
                    {
                        name: 'identification_number',
                        label: 'Número de identificación',
                        disabled: false,
                        type: 'integer',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'first_name',
                        label: 'Nombres',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'nombres'
                        ),
                    },
                    {
                        name: 'last_name',
                        label: 'Apellidos',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'apellidos'
                        ),
                    },
                    {
                        name: 'phone_number',
                        label: 'Número telefónico',
                        disabled: false,
                        type: 'celular',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'Número telefónico'
                        ),
                    },
                ],
            },
        },
        components: {
            formRecharge: {
                permisions: [],
                tittle: 'recargas',
                fields: [
                    {
                        name: 'usuario',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        url: '/globals/users/?is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(['required'], 'usuario'),
                    },
                    {
                        name: 'tipo_recarga',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: [],
                        url: '/globals/tipo_recarga/',
                        validation: validationDefault(
                            ['required'],
                            'tipo recarga'
                        ),
                    },
                    {
                        name: 'valor',
                        disabled: false,
                        type: 'price',
                        col: 3,
                        validation: validationDefault(['required'], 'valor'),
                    },
                ],
            },
            formUser: {
                permisions: [],
                tittle: 'recargas',
                fields: [
                    {
                        name: 'first_name',
                        label: 'Nombre',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(['required'], 'nombre'),
                    },
                    {
                        name: 'email',
                        label: 'Correo electrónico',
                        disabled: false,
                        type: 'email',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'correo electrónico'
                        ),
                    },
                    {
                        name: 'password',
                        label: 'Contraseña',
                        disabled: false,
                        type: 'password-eye',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'contraseña'
                        ),
                    },
                    {
                        name: 'identification_number',
                        label: 'Número de identificación',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Número de identificación'
                        ),
                    },
                    {
                        name: 'groups',
                        label: 'Rol',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        url: 'globals/roles/',
                        notSearchGlobals: true,
                        validation: validationDefault(['required'], 'rol'),
                    },
                    {
                        name: 'tipo_usuario',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        showTable: true,
                        options: genericOptions.tipo_usuario,
                        validation: validationDefault(
                            ['required'],
                            'tipo usuario'
                        ),
                    },
                ],
            },
        },
    },
};

export default Rules;
