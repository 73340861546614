import {
    iconRequest,
    iconClient,
    iconCalculator,
    iconSearch,
    iconAffiliate,
    iconReports,
    iconReportsDefault,
    iconCertificate,
    iconNeighborhood,
    iconZonification,
    iconUsers,
    iconParameters,
    iconDocs,
} from '../defaultValues';

const data = {
    role_24702d7c49234abdb6b9f1c903325b1d: [
        {
            id: 'gogo',
            icon: iconRequest,
            label: 'Solicitudes',
            to: '/app/credits',
        },
        {
            id: 'searcRequest',
            icon: iconSearch,
            label: 'Buscar Solicitud',
            to: '/app/reports/searchRequest',
        },
        {
            id: 'secondmenu',
            icon: iconClient,
            label: 'Clientes',
            to: '/app/clients',
        },
        {
            id: 'parametros',
            icon: iconParameters,
            label: 'Parámetros',
            to: '/app/calculator',
            subs: [
                {
                    icon: iconAffiliate,
                    label: 'Aliados',
                    to: '/app/aliados',
                },
                {
                    icon: iconNeighborhood,
                    label: 'Barrios',
                    to: '/app/barrios',
                },
            ],
        },
        {
            id: 'usuarios',
            icon: iconUsers,
            label: 'Usuarios',
            to: '/app/usuarios',
        },
        {
            id: 'zonification',
            icon: iconZonification,
            label: 'Zonificación',
            to: '/app/zonification',
        },
        {
            id: 'simulador-credito',
            icon: iconCalculator,
            label: 'Simulador de Crédito',
            to: '/app/calculator',
        },
        {
            id: 'blankpage',
            icon: iconReports,
            label: 'Reportes',
            to: '/app/blank-page',
            subs: [
                {
                    icon: iconReportsDefault,
                    label: 'Desempeño Usuario',
                    to: '/app/reports/TeamPerformance',
                },
            ],
        },
        {
            id: 'documentos',
            icon: iconDocs,
            label: 'Documentos',
            to: '/app/calculator',
            subs: [
                {
                    id: 'certificados',
                    icon: iconCertificate,
                    label: 'Certificados',
                    to: '/app/reports/PeaceAndSafe',
                },
            ],
        },
    ],
};
export default data;
