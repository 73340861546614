import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
} from '../defaultValues';

const data = {
    role_b0f7c326f7a54c1fa6f3a48454f2c95f: [
        {
            id: 'gogo',
            icon: 'iconsminds-air-balloon-1',
            label: 'Administración',
            to: `/gogo`,
            subs: [
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Usuario',
                    to: `/app/users`,
                },
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Recargas',
                    to: `/app/recharge`,
                },
            ],
        },
        {
            id: 'digital',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Contenido digital',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Adquirir cuenta',
                    to: `/second-menu/second`,
                },
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuenta',
                    to: `/second-menu/second`,
                },
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo cuenta',
                    to: `/second-menu/second`,
                },
            ],
        },
        {
            id: 'corresponsal',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Corresponsal bancario',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    icon: 'simple-icon-paper-plane',
                    label: 'Adquirir cuenta',
                    to: `/second-menu/second`,
                },
            ],
        },
    ],
};
export default data;
