import React from 'react';
import { NavLink } from 'react-router-dom';

/** Validations */
import replaceCharacters from '../../components/utilities/helpers';
import { RedirectTableCell } from '../../components/utilities/Table/RedirectTableCell';
import { TooltipTableCell } from '../../components/utilities/Table/TooltipTableCell';

const requestsListStatus = [
    {
        id: 'asignadas_gerente_zona',
        label: 'ASIGNADAS',
        status: '20',
        responsable: null,
        offices: true,
        columns: [
            {
                Header: () => 'Solicitud',
                id: 'id',
                Cell: ({ row }) => {
                    return (
                        <NavLink
                            to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                            className="finamiga-color">
                            {' '}
                            <span className="box-state">{row.original.pk}</span>
                        </NavLink>
                    );
                },
            },
            {
                Header: 'Fecha',
                accessor: 'created_at',
            },
            {
                Header: 'Producto',
                accessor: 'subproducto.label',
            },
            {
                Header: 'Regional',
                accessor: 'oficina.label',
            },
            {
                Header: () => 'Identificación',
                id: 'numero_identificacion',
                Cell: ({ row }) => (
                    <RedirectTableCell
                        route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                        label={row.original.persona.numero_identificacion}
                    />
                ),
            },
            {
                Header: 'Cliente',
                accessor: 'persona.full_name',
            },
            {
                Header: () => 'Ejecutivo',
                id: 'ejecutivo',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.name
                        }
                        tooltipText={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.email
                        }
                    />
                ),
            },
            {
                Header: () => 'Monto',
                id: 'monto',
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.monto_solicitado
                                ? replaceCharacters(
                                      'cast_amount',
                                      row.original.monto_solicitado
                                  )
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Plazo',
                accessor: 'plazo_solicitado',
            },
            {
                Header: () => 'Estado',
                id: 'estado_solicitud',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={row.original.estado_solicitud.label}
                        tooltipText={replaceCharacters(
                            'data-estate-request',
                            row.original
                        )}
                    />
                ),
            },
        ],
        buttonAction: [{ name: 'reassingUserRequest' }],
    },
    {
        id: 'tramitadas_gerente_zona',
        label: 'TRAMITADAS',
        status: '30,40',
        responsable: null,
        offices: true,
        columns: [
            {
                Header: () => 'Solicitud',
                id: 'id',
                Cell: ({ row }) => {
                    return (
                        <NavLink
                            to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                            className="finamiga-color">
                            {' '}
                            <span className="box-state">{row.original.pk}</span>
                        </NavLink>
                    );
                },
            },
            {
                Header: 'Fecha',
                accessor: 'created_at',
            },
            {
                Header: 'Producto',
                accessor: 'subproducto.label',
            },
            {
                Header: 'Regional',
                accessor: 'oficina.label',
            },
            {
                Header: () => 'Identificación',
                id: 'numero_identificacion',
                Cell: ({ row }) => (
                    <RedirectTableCell
                        route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                        label={row.original.persona.numero_identificacion}
                    />
                ),
            },
            {
                Header: 'Cliente',
                accessor: 'persona.full_name',
            },
            {
                Header: () => 'Ejecutivo',
                id: 'ejecutivo',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.name
                        }
                        tooltipText={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.email
                        }
                    />
                ),
            },
            {
                Header: () => 'Monto',
                id: 'monto',
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.monto_solicitado
                                ? replaceCharacters(
                                      'cast_amount',
                                      row.original.monto_solicitado
                                  )
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Plazo',
                accessor: 'plazo_solicitado',
            },
            {
                Header: () => 'Estado',
                id: 'estado_solicitud',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={row.original.estado_solicitud.label}
                        tooltipText={replaceCharacters(
                            'data-estate-request',
                            row.original
                        )}
                    />
                ),
            },
        ],
    },
    {
        id: 'aprobadas_gerente_zona',
        label: 'APROBADAS',
        status: '50,60',
        responsable: null,
        offices: true,
        columns: [
            {
                Header: () => 'Solicitud',
                id: 'id',
                Cell: ({ row }) => {
                    return (
                        <NavLink
                            to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                            className="finamiga-color">
                            {' '}
                            <span className="box-state">{row.original.pk}</span>
                        </NavLink>
                    );
                },
            },
            {
                Header: 'Fecha',
                accessor: 'created_at',
            },
            {
                Header: 'Producto',
                accessor: 'subproducto.label',
            },
            {
                Header: 'Regional',
                accessor: 'oficina.label',
            },
            {
                Header: () => 'Identificación',
                id: 'numero_identificacion',
                Cell: ({ row }) => (
                    <RedirectTableCell
                        route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                        label={row.original.persona.numero_identificacion}
                    />
                ),
            },
            {
                Header: 'Cliente',
                accessor: 'persona.full_name',
            },
            {
                Header: () => 'Ejecutivo',
                id: 'ejecutivo',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.name
                        }
                        tooltipText={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.email
                        }
                    />
                ),
            },
            {
                Header: () => 'Monto',
                id: 'monto',
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.monto_solicitado
                                ? replaceCharacters(
                                      'cast_amount',
                                      row.original.monto_solicitado
                                  )
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Plazo',
                accessor: 'plazo_solicitado',
            },
            {
                Header: () => 'Estado',
                id: 'estado_solicitud',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={row.original.estado_solicitud.label}
                        tooltipText={replaceCharacters(
                            'data-estate-request',
                            row.original
                        )}
                    />
                ),
            },
        ],
    },

    {
        id: 'desembolsadas_gerente_zona',
        label: 'DESEMBOLSADAS',
        status: '90',
        responsable: null,
        offices: true,
        columns: [
            {
                Header: () => 'Solicitud',
                id: 'id',
                Cell: ({ row }) => {
                    return (
                        <NavLink
                            to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                            className="finamiga-color">
                            {' '}
                            <span className="box-state">{row.original.pk}</span>
                        </NavLink>
                    );
                },
            },
            {
                Header: 'Fecha',
                accessor: 'created_at',
            },
            {
                Header: 'Producto',
                accessor: 'subproducto.label',
            },
            {
                Header: 'Regional',
                accessor: 'oficina.label',
            },
            {
                Header: () => 'Identificación',
                id: 'numero_identificacion',
                Cell: ({ row }) => (
                    <RedirectTableCell
                        route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                        label={row.original.persona.numero_identificacion}
                    />
                ),
            },
            {
                Header: 'Cliente',
                accessor: 'persona.full_name',
            },
            {
                Header: () => 'Ejecutivo',
                id: 'ejecutivo',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.name
                        }
                        tooltipText={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.email
                        }
                    />
                ),
            },
            {
                Header: () => 'Monto',
                id: 'monto',
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.monto_solicitado
                                ? replaceCharacters(
                                      'cast_amount',
                                      row.original.monto_solicitado
                                  )
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Plazo',
                accessor: 'plazo_solicitado',
            },
            {
                Header: () => 'Estado',
                id: 'estado_solicitud',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={row.original.estado_solicitud.label}
                        tooltipText={replaceCharacters(
                            'data-estate-request',
                            row.original
                        )}
                    />
                ),
            },
        ],
    },
    {
        id: 'rechazadas_gerente_zona',
        label: 'RECHAZADAS',
        status: '70,80',
        responsable: null,
        offices: true,
        columns: [
            {
                Header: () => 'Solicitud',
                id: 'id',
                Cell: ({ row }) => {
                    return (
                        <NavLink
                            to={`process/${row.original.persona.numero_identificacion}/${row.original.pk}/${row.original?.persona.pk}`}
                            className="finamiga-color">
                            {' '}
                            <span className="box-state">{row.original.pk}</span>
                        </NavLink>
                    );
                },
            },
            {
                Header: 'Fecha',
                accessor: 'created_at',
            },
            {
                Header: 'Producto',
                accessor: 'subproducto.label',
            },
            {
                Header: 'Regional',
                accessor: 'oficina.label',
            },
            {
                Header: () => 'Identificación',
                id: 'numero_identificacion',
                Cell: ({ row }) => (
                    <RedirectTableCell
                        route={`/app/clients/consult/${row.original.persona.numero_identificacion}/${row.original.persona.tipo_identificacion.value}`}
                        label={row.original.persona.numero_identificacion}
                    />
                ),
            },
            {
                Header: 'Cliente',
                accessor: 'persona.full_name',
            },
            {
                Header: () => 'Ejecutivo',
                id: 'ejecutivo',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.name
                        }
                        tooltipText={
                            row.original?.gestionsolicitud?.usuario_ejecutivo
                                ?.email
                        }
                    />
                ),
            },
            {
                Header: () => 'Monto',
                id: 'monto',
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.monto_solicitado
                                ? replaceCharacters(
                                      'cast_amount',
                                      row.original.monto_solicitado
                                  )
                                : '-'}
                        </div>
                    );
                },
            },
            {
                Header: 'Plazo',
                accessor: 'plazo_solicitado',
            },
            {
                Header: () => 'Estado',
                id: 'estado_solicitud',
                Cell: ({ row }) => (
                    <TooltipTableCell
                        label={row.original.estado_solicitud.label}
                        tooltipText={replaceCharacters(
                            'data-estate-request',
                            row.original
                        )}
                    />
                ),
            },
        ],
    },
];

export default requestsListStatus;
