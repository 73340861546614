import React from 'react';

export const TooltipTableCell = ({ label, tooltipText, emptyText = '-' }) => {
    if (tooltipText) {
        return (
            <div className="tooltipCss finamiga-color">
                {label ? label : emptyText}
                <span className="tooltiptext btn btn-outline-primary">
                    {tooltipText ? tooltipText : '-'}
                </span>
            </div>
        );
    } else {
        return <div>{label ? label : emptyText}</div>;
    }
};
