import React from 'react';
import { TooltipTableCell } from '../TooltipTableCell';
import replaceCharacters from '../../helpers';

export const StatusWithTooltip = {
    Header: () => 'Estado',
    id: 'estado_solicitud',
    Cell: ({ row }) => (
        <TooltipTableCell
            label={row.original.estado_solicitud.label}
            tooltipText={replaceCharacters('data-estate-request', row.original)}
        />
    ),
};
