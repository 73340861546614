import {
    iconRequest,
    iconClient,
    iconCalculator,
    iconReportsDefault,
    iconSearch,
} from '../defaultValues';

const data = {
    role_8f4f037bf18d4c548fd80acf80d30c58: [
        {
            id: 'gogo',
            icon: iconRequest,
            label: 'Solicitudes',
            to: '/app/credits',
        },
        {
            id: 'searcRequest',
            icon: iconSearch,
            label: 'Buscar Solicitud',
            to: '/app/reports/searchRequest',
        },
        {
            id: 'secondmenu',
            icon: iconClient,
            label: 'Clientes',
            to: '/app/clients',
        },
        {
            id: 'cartera',
            icon: iconReportsDefault,
            label: 'Cartera',
            to: '/app/calculator',
            subs: [
                {
                    id: 'mora-collection',
                    icon: iconReportsDefault,
                    label: 'Mora',
                    to: '/app/collection/lista',
                },
                {
                    id: 'par1&par30-collection',
                    icon: iconReportsDefault,
                    label: 'Par1 & Par30',
                    to: '/app/collection/per1AndPer30',
                },
                {
                    id: 'par1&par30C-collection',
                    icon: iconReportsDefault,
                    label: 'Comparativo',
                    to: '/app/collection/per1AndPer30Comparative',
                },
            ],
        },
        {
            id: 'simulador-credito',
            icon: iconCalculator,
            label: 'Simulador de Crédito',
            to: '/app/calculator',
            subs: [
                {
                    icon: iconCalculator,
                    label: 'Simulador de Créditos',
                    to: '/app/calculator',
                },
                {
                    icon: iconCalculator,
                    label: 'Calculadora R&R',
                    to: '/app/reports/calculatorRefinancing',
                },
            ],
        },
    ],
};
export default data;
