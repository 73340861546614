/**
 * Return values in the range of [0, 1)
 */
export const randomFloat = function () {
    if (window.crypto) {
        return window.crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    } else {
        return Math.random();
    }
};
/**
 *
 * @param {Date} today
 * @returns the given date string with format YYYY-MM-DD, less one to the day
 */
export const getWithFormatDate = (today) => {
    today.setDate(today.getDate() + -1);
    const dd2 = String(today.getDate()).padStart(2, '0');
    const mm2 = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy2 = today.getFullYear();
    return yyyy2 + '-' + mm2 + '-' + dd2;
};
/**
 *
 * @param {Date} today
 * @returns the given date string with format YYYY-MM-DD with the DD in 01 set
 */
export const FirstDayActualMonth = (today) => {
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-01';
};
/**
 *
 * @param {Date} today
 * @returns the given date string with format YYYY-MM-DD with the DD in 01 set
 */
export const FirstDayLastMonth = (today) => {
    today.setDate(today.getDate());
    today.setMonth(today.getMonth() - 1);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
};
/**
 *
 * @param {Date} today
 * @returns the given date string with format YYYY-MM-DD
 */
export const getActualDateWithFormatDate = (today) => {
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
};

export const getValueFromObjectWithString = (object, string) => {
    const reduxParams = string.split('.');
    let parameter = object;
    return reduxParams
        .map((subitem) => {
            parameter = parameter[subitem];
            return parameter;
        })
        .pop();
};
