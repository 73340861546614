import React from 'react';
import axios from 'axios';

export default function replaceCharacters(type, value) {
    switch (type) {
        case 'replace':
            return removeCharacters(value);
        case 'fecha_create_update':
            return castDateCreateUpdate(value);
        case 'cast_amount':
            return castAmount(value);
        case 'change_icon_collapse':
            return changeIconCollapse(value);
        case 'get-value-object':
            return getValueObject(value);
        case 'replace-price':
            return removeCharactersPrice(value);
        case 'date-report':
            return dateReport();
        case 'download-report':
            return downloadRepor(value);
        case 'replace-price-decimal':
            return removeCharactersPriceDecimal(value);
        case 'clean-input-form':
            return cleanInputForm(value);
        case 'data-estate-request':
            return dataEstateRequest(value);
        case 'text-tranform-uppercase':
            return textTranformUppercase(value);
        case 'format_number_id':
            return numberIdFormat(value);
        case 'format_number_phone':
            return formatPhoneNumber(value);
        case 'tex_transform_string':
            return texTransformString(value);
        case 'replace_data':
            return replaceDate(value);
        default:
            return null;
    }
}

function removeCharacters(value) {
    if (value && typeof value !== 'number') {
        var data = value;
        data = data.replace('$', '').replace(/,/g, '').replace(/-/g, '').trim();
        return data;
    } else if (value !== undefined && value !== null) {
        return value.toString();
    }
}
function castDateCreateUpdate(value) {
    if (value) {
        let fecha = new Date(Date.parse(value))
            .toLocaleString()
            .replace(/\//g, '-')
            .replace(',', '')
            .replace('p. m.', 'pm')
            .replace('a. m.', 'am');

        return fecha;
    }
}

function castAmount(value) {
    if (value && value > 0) {
        //separador de miles con (.)
        //var formatter = new Intl.NumberFormat('es-CO');

        //separador de miles con  (,)
        var formatter = new Intl.NumberFormat('es-US');
        return '$' + formatter.format(value);
    } else if (value === undefined) {
        return '';
    } else {
        return '$' + value;
    }
}

function changeIconCollapse(value) {
    if (value) {
        return 'simple-icon-arrow-up-circle';
    } else {
        return 'simple-icon-arrow-down-circle';
    }
}

function getValueObject(value) {
    if (value) {
        for (const itemForm in value) {
            if (
                typeof value[itemForm] === 'object' &&
                value[itemForm] !== null
            ) {
                if (value[itemForm].length === undefined) {
                    value[itemForm] = value[itemForm].value;
                } else {
                    if (value[itemForm].length > 0) {
                        value[itemForm] = value[itemForm].map((item) => {
                            return item?.value;
                        });
                    }
                }
            }
        }
        return value;
    }
}

function removeCharactersPrice(value) {
    if (typeof value !== 'number' && value) {
        if (value !== null && value !== undefined) {
            value = parseFloat(
                value
                    .replace('$', '')
                    .replace(/,/g, '')
                    .replace(/-/g, '')
                    .trim()
            );
        }
        return value;
    } else {
        return value;
    }
}

function dateReport() {
    var f = new Date();

    const anio = f.getFullYear();
    const mes =
        f.getMonth() + 1 <= 9 ? '0' + (f.getMonth() + 1) : f.getMonth() + 1;
    const dia = f.getDate() + 1 <= 9 ? '0' + f.getDate() : f.getDate();
    var fecha_actual = `${anio}${mes}${dia}`;

    return fecha_actual.trim();
}

async function downloadRepor(paramsSearch) {
    //try {
    const res = await axios({
        url: `${paramsSearch.url}`,
        method: 'GET',
        responseType: 'blob', // important
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${paramsSearch.nameReport}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    return res;
}

function removeCharactersPriceDecimal(value) {
    if (value && typeof value !== 'number') {
        var data = value;
        data = parseFloat(
            data.replace('$', '').replace(/,/g, '').replace(/-/g, '').trim()
        );
        return data;
    } else {
        return value;
    }
}

function cleanInputForm(fields) {
    if (fields) {
        var data = {};
        fields.forEach((item) => {
            if (item.cleanInput !== undefined && !item.cleanInput) {
            } else {
                data[item.name] = '';
            }
        });
        return data;
    }
}

function dataEstateRequest(value) {
    var fecha = '';
    var responsable = '';

    if (value.estado_solicitud.label === 'RECHAZADA') {
        fecha = value.gestionsolicitud.fecha_rechazo;
        responsable = value.gestionsolicitud.usuario_rechazo?.name;
    } else if (value.estado_solicitud.label === 'DESISTIDA') {
        fecha = value.gestionsolicitud.fecha_desistimiento;
        responsable = value.gestionsolicitud.usuario_desistimiento?.name;
    }

    return (
        <>
            {responsable ? (
                <ul>
                    <li
                        style={{
                            listStyleType: 'none',
                        }}>
                        Fecha: {fecha}
                    </li>
                    <li
                        style={{
                            listStyleType: 'none',
                        }}>
                        Responsable: {responsable}
                    </li>
                </ul>
            ) : (
                '-'
            )}
        </>
    );
}

function textTranformUppercase(value) {
    if (value) {
        for (const itemForm in value) {
            if (typeof value[itemForm] === 'string') {
                value[itemForm] = value[itemForm]
                    ? value[itemForm].toUpperCase()
                    : '';
            }
        }
        return value;
    }
}

/**
 * @method
 * @param {number} numbreIdToFormate number to format
 * @description function responsable of set a format with point to a number
 */
const numberIdFormat = (numbreIdToFormate) => {
    try {
        return numbreIdToFormate
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } catch (error) {
        return numbreIdToFormate;
    }
};

/**
 * @method
 * @param {number} phoneNumberString number to format
 * @description function responsable of set a format with a special phone number format
 */
const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

/**
 * @method
 * @param {number} phoneNumberString number to format
 * @description function responsable of set a format with a special phone number format
 */
const texTransformString = (value) => {
    if (value) {
        for (const itemForm in value) {
            if (value[itemForm] === null) {
                value[itemForm] = value[itemForm] ? value[itemForm] : '';
            }
        }
        return value;
    }
};

const replaceDate = (fecha) => {
    return fecha.substring(-1, 10);
};
