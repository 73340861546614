import React from 'react';
import { RequestIdTableCell } from '../RequestIdTableCell';
import { RequestId, RequestIdWithoutRedirect } from './RequestId';
import { TooltipTableCell } from '../TooltipTableCell';

export const RequestIdWithWarning = (dateField = 'created_at') => {
    return {
        Header: () => 'solicitud',
        id: 'id',
        Cell: ({ row }) => {
            return (
                <RequestIdTableCell
                    row={row}
                    comparativeDateField={dateField}
                />
            );
        },
    };
};
export const RequestIdWithWarningAliado = (
    aliado,
    dateField = 'created_at'
) => {
    if (aliado) {
        return {
            Header: () => 'solicitud',
            id: 'id',
            Cell: ({ row }) => {
                return (
                    <RequestIdTableCell
                        row={row}
                        comparativeDateField={dateField}
                    />
                );
            },
        };
    } else {
        return {
            Header: () => 'solicitud',
            id: 'id2',
            Cell: ({ row }) => {
                return (
                    <TooltipTableCell
                        label={row.original.pk}
                        tooltipText={row.original.pk}
                    />
                );
            },
        };
    }
};
export const replaceRequestId = (columns) => {
    const index = columns.map((e) => e.id).indexOf('id');
    if (~index) {
        columns[index] = RequestId;
    }
    return columns;
};

export const replaceRequestIdWithoutRedirect = (columns) => {
    const index = columns.map((e) => e.id).indexOf('id');
    if (~index) {
        columns[index] = RequestIdWithoutRedirect;
    }
    return columns;
};
